import React, { useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { SelectItem } from '../../../models/Models';

// Komponenty
import { Backdrop, Box, CircularProgress, Divider, FormControl, IconButton, InputLabel, ListItemIcon, Menu, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ContentCategoryCreate, { ContentCategoryCreateProps } from './ContentCategoryCreate';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import MessageBox, { MessageBoxProps } from '../../../components/MessageBox';

// Ikony
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface CategoriesProps {
    categories: SelectItem[];      // Zoznam kategórii (zdroj)
    selectedId: number;                 // Id aktívnej kategórie
    onSelect?: (id: number) => void;    // Udalosť po kliknutí na položku (reakciou by mala byť napr. zmena selectedId)
    onChanged?: () => void;             // Udalosť po zmene v zozname (pridať, upraviť, vymazať, ...)
}

const Categories = (props: CategoriesProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [contentCategoryCreate, setContentCategoryCreate] = useState<ContentCategoryCreateProps>({
        open: false,
        categories: [],
        keepMounted: true,
        onSave: props.onChanged,
        onClose: () => setContentCategoryCreate(prev => ({ ...prev, open: false }))
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({
        open: false, title: '', children: null, onClose: () => {
            setMessageBox(prev => ({
                ...prev, open: false
            }));
        }
    });

    // Kontextové menu
    const [categoriesMenuItemId, setCategoriesMenuItemId] = useState<number>();
    const [categoriesMenuEl, setCategoriesMenuEl] = useState<null | HTMLElement>(null);

    // Funkcia pre otvorenie kontexového menu kategórie
    const handleCategoriesSelectMenuOpen = (e: HTMLElement, id: number) => {
        setCategoriesMenuItemId(id);
        setCategoriesMenuEl(e);
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number, parentId?: number) => {
        setContentCategoryCreate(prev => ({
            ...prev,
            id: id,
            parentId: parentId,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať túto kategóriu a jej pod-kategórie?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'contentcategory/' + id)
                    .then(response => {
                        if (response.data === true && props.onChanged !== undefined) {
                            props.onChanged();
                        }
                        if (response.data === true && props.onSelect !== undefined) {
                            props.onSelect(0);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <ContentCategoryCreate {...contentCategoryCreate} categories={props.categories} />

            {/* Kontextové menu položky */}
            <Menu id="menu-categories" anchorEl={categoriesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(categoriesMenuEl)} onClose={() => setCategoriesMenuEl(null)} >
                <MenuItem onClick={() => { handleCreate(0, categoriesMenuItemId ?? 0); setCategoriesMenuEl(null); }}>
                    <ListItemIcon><AddIcon fontSize="small" /></ListItemIcon> Vložiť novú kategóriu
                </MenuItem>
                <MenuItem onClick={() => { handleCreate(categoriesMenuItemId ?? 0, 0); setCategoriesMenuEl(null); }}>
                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť kategóriu
                </MenuItem>
                <MenuItem onClick={() => { handleDelete(categoriesMenuItemId ?? 0, props.categories.find(d => d.id === categoriesMenuItemId)?.name ?? ''); setCategoriesMenuEl(null); }}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať kategóriu
                </MenuItem>
            </Menu>

            {/* Zoznam kategórii (otvárací zoznam) */}
            <Box sx={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="content-categories-category">Kategória</InputLabel>
                    <Select fullWidth labelId="content-categories-category" label="Kategória" value={props.selectedId ?? 0} sx={{ flex: 1 }} onChange={(e) => { if (props.onSelect !== undefined) { props.onSelect((parseInt(e.target.value.toString()))); } }}>
                        <MenuItem key={0} value={0}>-</MenuItem>
                        {props.categories.map(contentCategory => (<MenuItem key={contentCategory.id} value={contentCategory.id}>{contentCategory.name}</MenuItem>))}
                    </Select>
                </FormControl>
                <IconButton aria-label="Možnosti" title="Možnosti" disabled={props.selectedId <= 0} onClick={(e) => { handleCategoriesSelectMenuOpen(e.currentTarget, props.selectedId); }}>
                    <MoreVertIcon fontSize="small" />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton aria-label="Nová kategória" title="Nová kategória" onClick={() => { handleCreate(0, (props.selectedId > 0 ? props.selectedId : 0)); }}>
                    <CreateNewFolderIcon fontSize="small" />
                </IconButton>
            </Box>

        </React.Fragment>
    )
}

export default Categories;