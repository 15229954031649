// Integrácia: https://www.tiny.cloud/docs/integrations/react/, https://pretagteam.com/question/loading-tinymce-without-cloud-in-react 
/*  
    Základný TinyMCE Core je navždy zdarma aj na komerčné účely.
    Ak sa editor alebo niektorá časť naťahuje z cloudu, tak musí byť doména registrovaná v cloude, inak sa zobrazí upozornenie.
    Predvolene sa všetky súčasti naťahujú automaticky z cloudu, takže pre vyhnutie sa hlášky (nepoužívania clodu) je potrebné načítať súčasti z projektu (import xy...).
    Je to tak preto, že ak by sa niekto rozhodol používať platené súčasti, stačí zapnúť súčasť a doplniť api key konfiguráciu editora a je to funkčné bez inštalácie súčastí.
*/

// Poznámky
/*
    MUI predvolene blokuje opustenie modálneho dialógového okna (Modal, Dialog, ...) to bráni v interakcii z akýmkoľvek kontextovými oknami TinyMCE. 
    Riešením je použitie atribútu "disableEnforceFocus" v nadradenom okne.
*/

// TinyMCE
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/visualblocks';

import 'tinymce/skins/ui/oxide/skin.min.css';
// import 'tinymce/skins/ui/oxide/content.min.css';
//import 'tinymce/skins/content/default/content.min.css'; // poškodí layout admina

// Modely
import { File } from '../../models/Models';

// Komponenty
import { Editor as TinyMCEEditor } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import FilesDialog, { FilesDialogProps } from '../file/FilesDialog';
import { useRef, useState } from 'react';
import { TinyMCEStyleSkin } from './TinyMCEStyle';
import { TinyMCEStyleClient, TinyMCEStyleClientVisual } from './TinyMCEStyleClient';
import { TinyMCETemplates } from './TinyMCETemplates';
import { addExtraToolbar } from './TinyMCEExtra';

// Vstupné parametre
export interface TinyMCEProps {
    content: string;
    contentStyle?: string;
    height?: string | number;
    onChange?: (content: string) => void;
    onCancel?: () => void;
}

// Predpis pre ono výber súboru
interface FilesDialogArgument {
    image: boolean;
    callback: (src: string, alt: string) => void;
}

// Komponent pre zoznam užívateľov
const TinyMCE = (props: TinyMCEProps) => {

    // Stav
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        watermark: false,
        onSelect: (files: File[], argument: FilesDialogArgument) => {
            if (files.length > 0) {
                // Ak požadujem obrázok, ale vybral som iný súbor, tak ďalej nepokračujem
                if (argument.image && !files[0].fileIsImage) {
                    return;
                }
                // Odovzdám súbor
                argument.callback(files[0].fileSrc ?? '', files[0].name ?? '');
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = (argument: FilesDialogArgument) => {
        setFilesDialog(prev => ({ ...prev, open: true, argument: argument }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Udalosti
    const handleEditorChange = (content: any, editor: any) => {
        if (props.onChange) {
            props.onChange(content as string);
        }
    }

    const editorRef = useRef<TinyMCEEditor | null>(null);

    return (
        <>
            <FilesDialog {...filesDialog} />
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                init={{
                    skin: false,
                    height: props.height ?? '100%',
                    cleanup: false,
                    menubar: true,
                    content_css: true,
                    automatic_uploads: false,
                    paste_as_text: true,
                    paste_block_drop: false,
                    image_advtab: true,
                    relative_urls: false,
                    remove_script_host: false,
                    convert_urls: false,
                    language: 'sk',
                    language_url: process.env.PUBLIC_URL + '/assets/tinymce-sk.js',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code template hr',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | extra visualblocks | formatselect | fontsizeselect | ' +
                        'bold italic | forecolor backcolor | image link | ' +
                        'alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | ' +
                        'removeformat',
                    extended_valid_elements: 'div[class|style],h3[onclick]',
                    setup: (editor: TinyMCEEditor) => {
                        addExtraToolbar(editor);
                    },
                    block_formats: 
                        'Odstavec=p;' +
                        'Nadpis 1=h1;' +
                        'Nadpis 2=h2;' +
                        'Nadpis 3=h3;' +
                        'Nadpis 4=h4;' +
                        'Nadpis 5=h5;' +
                        'Nadpis 6=h6;' +
                        'Blok (div)=div;' +
                        'Predformátované=code;',
                    visual: true,
                    visualblocks_default_state: true,
                    visual_table_class: 'table-visual-border',
                    templates: TinyMCETemplates,
                    indentation: "10px",
                    fontsize_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 24px 30px 36px 48px 60px 72px 96px",
                    image_class_list: [
                        { title: "None", value: "" },
                        { title: "Gallery", value: "mce-img-gallery" },
                        { title: "Responsive", value: "mce-img-responsive" },
                        { title: "Rounded", value: "mce-img-rounded" },
                        { title: "Primary", value: "mce-img-primary" },
                        { title: "Secondary", value: "mce-img-secondary" },
                    ],
                    link_class_list: [
                        { title: 'None', value: '' },
                        { title: 'Primary button', value: 'mce-btn' },
                        { title: 'Primary button (large)', value: 'mce-btn-lg' },
                        { title: 'Secondary button', value: 'mce-btn-sec' },
                        { title: 'Secondary button (large)', value: 'mce-btn-sec-lg' }
                    ],
                    table_class_list: [
                        { title: 'None', value: '' },
                        { title: 'Responsive table', value: 'mce-tbl-responsive' },
                        { title: 'Responsive table (600px)', value: 'mce-tbl-responsive-600' },
                        { title: 'Responsive table (1200px)', value: 'mce-tbl-responsive-1200' },
                        { title: 'Primary table', value: 'mce-tbl-primary' },
                        { title: 'Secondary table', value: 'mce-tbl-secondary' },
                    ],
                    content_style: (props.contentStyle ?? '') +
                        TinyMCEStyleClient +
                        TinyMCEStyleClientVisual +
                        TinyMCEStyleSkin,
                    file_picker_callback: function (callback, value, meta) {
                        var argImage = (meta.filetype === 'image');
                        var argCallback = (src: string, alt: string) => {
                            callback(src, {
                                alt: alt
                            });
                        };
                        handleFilesOpen({ image: argImage, callback: argCallback });
                    }
                }}
                value={props.content}
                onEditorChange={handleEditorChange}
            />
        </>
    )
}

export default TinyMCE;