import React from 'react';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Currency, DomainDefaultPage, DefaultPageTypes, Department, Domain, Language, PriceList } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, FormControlLabel, Tooltip, Box, Switch, Accordion, AccordionDetails, AccordionSummary, Typography, ListSubheader } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { EscapeUrl } from '../../../utility/Text';

// Ikony
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Domain => ({
    id: 0,
    actived: true,
    default: false,
    blocked: false,
    strict: false,
    languageId: 0,
    currencyId: 0,
    name: '',
    url: '',
    urlRedirect: '',
    defaultPages: []
});

// Vstupné parametre
export interface DomainCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const DomainCreate = (props: DomainCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Domain>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [departmentsGrouped, setDepartmentsGrouped] = useState<any[]>([]);

    // Automaticky získam zoznam prevádzok zo stredísk
    useEffect(() => {
        var items: any[] = [];
        var branchNames = Array.from(new Set(departments?.map(item => item.branchName ?? '') ?? []));
        branchNames?.forEach(branchName => {
            items.push(<ListSubheader component="div" disableSticky sx={{ maxHeight: '25px', lineHeight: '25px', background: 'none', fontSize: 'small' }}>{branchName}</ListSubheader>);
            departments?.filter(item => item.branchName === branchName)?.forEach(item => items.push(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>));
        });
        setDepartmentsGrouped(items);
    }, [departments]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Načítam číselník
    const loadDepartments = () => {
        axios
            .get(AppConfig.ApiUri + 'department')
            .then(response => {
                setDepartments((response.data as Department[]).filter(item => item.actived));
            });
    };
    useEffect(() => loadDepartments(), []);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Domain);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam jazykov
    const loadLanguages = () => {
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Načítam zoznam mien
    const loadCurrencies = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'currency')
            .then(response => {
                setCurrencies(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadCurrencies(), []);

    // Načítam zoznam cenníkov
    const loadPriceLists = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pricelist')
            .then(response => {
                setPriceLists(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadPriceLists(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeDefaultPages = (type: number, url: string) => {
        setSource(prev => {
            const item: DomainDefaultPage = { ...prev?.defaultPages?.find(item => item.type === type), type: type, url: url };
            const next = { ...prev, defaultPages: [...prev?.defaultPages?.filter(item => item.type !== type) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if ((source.departmentId ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'languageId', text: 'Musíte vybrať stredisko!' });
            return;
        }
        if ((source.languageId ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'languageId', text: 'Musíte vybrať jazyk!' });
            return;
        }
        if ((source.currencyId ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'currencyid', text: 'Musíte vybrať menu!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'domain', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Doména' : 'Nová doména')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel control={<Switch checked={source.blocked} name="blocked" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Režim údržby" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel control={<Switch checked={source.default} name="default" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Predvolená" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel control={<Switch checked={source.strict} name="strict" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Prísne zobrazenie" />
                            </Grid>

                            {(source.strict ?? false) === true && (
                                <Grid item xs={12} mt={1}>
                                    <Alert severity="warning" sx={{ mb: 1 }}>
                                        V režime prísneho zobrazenia sa na doméne zobrazia len produkty a kategórie, ktoré majú označenú danú doménu.
                                        Štandardne ak nie je označená žiadna doména, tak sa záznam zobrazí na všetkých doménach.
                                    </Alert>
                                </Grid>
                            )}

                            {(source.blocked ?? false) === true && (
                                <Grid item xs={12} mt={1}>
                                    <Alert severity="warning" sx={{ mb: 1 }}>
                                        V režime údržby doména nie je prístupná (prístup je zablokovaný). Prístup je možný pomocu URL (platnosť 24h): "{source.url + '?access=actived'}".
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12} md={8} mt={1}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LanguageIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4} mt={1}>
                                <FormControl required margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="domain-create-department">Stredisko (oddelenie)</InputLabel>
                                    <Select labelId="domain-create-department" label="Stredisko (oddelenie)" value={source.departmentId ?? 0} name="departmentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'departmentId' || props.autoFocus === 'departmentName') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {departmentsGrouped.map(item => item)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl required margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="domain-create-language">Jazyk</InputLabel>
                                    <Select labelId="domain-create-langauge" label="Jazyk" value={source.languageId ?? 0} name="languageId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'languageCode' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {languages?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="customergroup-create-pricelist">Východzí cenník</InputLabel>
                                    <Select labelId="customergroup-create-pricelist" label="Východzí cenník" value={source.priceListId ?? 0} name="priceListId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'priceListId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {priceLists?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl required margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="domain-create-currency">Mena</InputLabel>
                                    <Select labelId="domain-create-currency" label="Mena" value={source.currencyId ?? 0} name="currencyId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'currencyCode' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {currencies?.map(item => <MenuItem key={item.id} value={item.id}>{item.code}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth margin="dense" name="url" type="url" label="Url adresa" variant="outlined" autoComplete="url" value={source.url ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'url' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="URL adresa domény, je potrebné zadať celú doménu aj s https://...">
                                                    <LinkIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="urlRedirect" type="url" label="Url adresa (presmerovanie)" variant="outlined" autoComplete="url" value={source.urlRedirect ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'urlRedirect' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Aktivuje automatické presmerovanie na zadanú URL adresu, je potrebné zadať celú doménu aj s https://...">
                                                    <LinkIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('default_pages')} onChange={() => handleToggleAccordion('default_pages')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Predvolené URL stránky ({source.defaultPages?.filter(item => (item.url ?? '').length > 0).length}/{DefaultPageTypes.length})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {DefaultPageTypes.map(type =>
                                                <Grid key={type.id} item xs={12} md={6}>
                                                    <TextField margin="dense" label={type.name} fullWidth variant="outlined" autoComplete="off"
                                                        value={source.defaultPages?.find(item => item.type === type.id)?.url ?? ''}
                                                        onChange={(e) => { handleChangeDefaultPages((type?.id ?? 0), EscapeUrl(e.target.value, false)) }}
                                                        inputProps={{ maxLength: 255 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <LinkIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>
                                            )}
                                            <Grid item xs={12} mt={1}>
                                                <Alert severity="info">Podľa nastavených URL adries systém rozpozná cieľové stránky domény.</Alert>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DomainCreate;