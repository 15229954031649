import { useEffect, useState } from 'react';

// Komponenty
import { Alert, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import ContentTagsDialog, { ContentTagsDialogProps } from '../settings/content_tag/ContentTagsDialog';
import TinyMCE from './TinyMCE';

// Vstupné parametre
export interface TinyMCEDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    title?: string;
    content: string;
    contentStyle?: string;
    contentTagsIds?: number[];
    onSave?: (content: string, argument?: any) => void;
    onClose?: () => void;
}

const TinyMCEDialog = (props: TinyMCEDialogProps) => {

    // Lokálny stav
    const [content, setContent] = useState<string>('');
    const [contentTagsDialog, setContentTagsDialog] = useState<ContentTagsDialogProps>({
        ids: [],
        open: false,
        onClose: () => handleContentTagsClose()
    });

    // Zobrazenie okna značiek
    const handleContentTagsOpen = () => {
        setContentTagsDialog(prev => ({ ...prev, ids: props.contentTagsIds ?? [], open: true }));
    };
    const handleContentTagsClose = () => {
        setContentTagsDialog(prev => ({ ...prev, open: false }));
    };

    // Vložím obsah do stavu
    useEffect(() => { setContent(props.content); }, [props.content, props.open]);

    // Udalosti zmien
    const handleSave = () => {
        if (props?.onSave) {
            props.onSave(content, props.argument);
        }
    };
    const handleClose = () => {
        if (props?.onClose) {
            props.onClose();
        }
    };

    return (
        <>
            <ContentTagsDialog {...contentTagsDialog} />
            <Dialog maxWidth='xl' fullWidth open={props.open} scroll="body" disableEnforceFocus onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                <DialogContent sx={{ height: '85vh', padding: 1, paddingBottom: 0 }}>
                    <TinyMCE onCancel={props.onClose} onChange={(s) => setContent(s)} content={content} contentStyle={props.contentStyle} />
                </DialogContent>
                <DialogActions sx={{ paddingTop: 1 }}>
                    {(props?.contentTagsIds !== undefined &&
                        <Alert sx={{ marginRight: 'auto' }} severity="info" action={<Button variant="contained" color="primary" size="small" onClick={handleContentTagsOpen}>Značky</Button>}>
                            V obsahu je možné použiť značky pre vloženie dynamického textu ako dátum, údaje z dokladu a podobne.
                        </Alert>
                    )}
                    <Button onClick={handleClose}>Späť</Button>
                    <Button onClick={handleSave} type="submit" variant="contained">Uložiť</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TinyMCEDialog;