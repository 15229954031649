import { useEffect, useState } from 'react';

// Modely
import { Category } from '../../models/Models';

// Komponenty
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import Categories from '../category/Categories';

export interface CategorySelectDialogProps {
    open: boolean;
    keepMounted?: boolean;
    activeCategoryId?: number;          // Aktuálne označená kategória
    hiddenCategoryId?: number;          // Skrytá vetva kategórie, napr. ak vyberám nadradenú kategóriu tak nemôžem dovoliť vybrať seba samú, inak hrozí zacyklenie a to, že sa nevykreslí.
    categories: Category[];             // Zoznam kategórii
    onCategoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSelect: (id: number) => void;
    onClose: () => void;
}

const CategorySelectDialog = (props: CategorySelectDialogProps) => {

    // Stav
    const [activeCategoryId, setActiveCategoryId] = useState<number | undefined>(props.activeCategoryId ?? 0);

    // Aktívnu kategóriu posúvam do zoznamu cez stav, aby som vedel kategórie zbaliť nastavením hodnoty "undefined"
    useEffect(() => {
        setActiveCategoryId(props.activeCategoryId);
    }, [props.activeCategoryId]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => props.onClose()}>
                <DialogContent sx={{ maxHeight: '75vh', padding: 1 }}>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={12} mt={0.5}>
                            <Categories
                                categories={props.categories}
                                activeCategoryId={activeCategoryId}
                                hiddenCategoryId={props.hiddenCategoryId}
                                hideUncategorized={true}
                                onCategoriesChanged={props.onCategoriesChanged}
                                onClick={(id: number) => {
                                    props.onSelect(id);
                                    props.onClose();
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingTop: 1 }}>
                    <Button onClick={() => {
                        if (activeCategoryId === undefined) {
                            setActiveCategoryId(0);
                        }
                        setTimeout(() => setActiveCategoryId(undefined), 50);
                    }} color="secondary" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Zbaliť kategórie</Button>
                    <Button onClick={props.onClose}>Späť</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CategorySelectDialog;