import React, { useState } from 'react';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../utility/Number';
import { EscapeSlug } from '../../utility/Text';
import { NullMinDate } from '../../utility/Date';

// Modely
import { CustomerGroup, Domain, Shipment, StockAvailability, Unit, Tag, ProductTag, ExternalCatalog, ProductExternalCatalog, Category, Modules } from '../../models/Models';
import { ProductCreateComponentProps } from './ProductCreate';

// Komponenty
import { TextField, FormControl, InputLabel, Select, MenuItem, Typography, Tooltip, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableHead, TableRow, Box, Tab, Tabs, Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ProductCreateCategory from './ProductCreateCategory';
import CustomFieldValues from '../settings/custom_field/CustomFieldValues';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import ScaleIcon from '@mui/icons-material/Scale';
import CalculateIcon from '@mui/icons-material/Calculate';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TagIcon from '@mui/icons-material/Tag';
import HeightIcon from '@mui/icons-material/Height';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import LinkIcon from '@mui/icons-material/Link';
import ExtensionIcon from '@mui/icons-material/Extension';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EuroIcon from '@mui/icons-material/Euro';
import SortIcon from '@mui/icons-material/Sort';
import HttpIcon from '@mui/icons-material/Http';
import { TabContext, TabPanel } from '@mui/lab';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateBasicProps extends ProductCreateComponentProps {
    domains: Domain[];
    stockAvailabilities: StockAvailability[];
    customerGroups: CustomerGroup[];
    categories: Category[];
    shipments: Shipment[];
    tags: Tag[],
    units: Unit[],
    vatRates: number[];
    externalCatalogs: ExternalCatalog[];
    externalCatalogsToken: string;
    onCategoriesChanged: () => void;    // Udalosť pre oznámenie zmeny v kategóriach (vnútorne ich môžem upraviť)
};

const ProductCreateBasic = (props: ProductCreateBasicProps) => {

    // Stav
    const [tabSeoValue, setTabSeoValue] = useState<string>('0');

    const handleChangeTag = (id: number, property: string, value: any, remove: boolean, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        props.setSource(prev => {
            if (remove) {
                return { ...prev, tags: prev.tags?.filter(item => item.tagId !== id) ?? [] }
            }
            else {
                const item: ProductTag = { ...prev?.tags?.find(item => item.tagId === id), tagId: id, [property]: value };
                const next = { ...prev, tags: [...prev?.tags?.filter(item => item.tagId !== id) ?? [], item] };
                return next;
            }
        });
    }

    const handleChangeExternalCatalog = (id: number, property: string, value: any, remove: boolean) => {
        props.setSource(prev => {
            if (remove) {
                return { ...prev, externalCatalogs: prev.externalCatalogs?.filter(item => item.externalCatalogId !== id) ?? [] }
            }
            else {
                const item: ProductExternalCatalog = { ...prev?.externalCatalogs?.find(item => item.externalCatalogId === id), externalCatalogId: id, [property]: value };
                const next = { ...prev, externalCatalogs: [...prev?.externalCatalogs?.filter(item => item.externalCatalogId !== id) ?? [], item] };
                return next;
            }
        });
    }

    // Funkcia pre posunutie spoločných vstupných parametrov
    const componentProps = (): ProductCreateComponentProps => {
        return {
            source: props.source,
            languages: props.languages,
            autoFocus: props.autoFocus,
            priceIncludeVat: props.priceIncludeVat,
            vatRateDefault: props.vatRateDefault,
            setSource: props.setSource,
            setFocus: props.setFocus,
            onChange: props.onChange,
            onChangeTranslation: props.onChangeTranslation,
            onToggleAccordion: props.onToggleAccordion,
            isAccordionExpanded: props.isAccordionExpanded
        }
    };

    return (
        <>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('tags')} onChange={() => { props.onToggleAccordion('tags') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Štítky ({props.source.tags?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                        <div style={{ flexGrow: 1 }}>
                            <Table size="small" sx={{ minWidth: '500px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ width: '30%' }}>Aktívny od</TableCell>
                                        <TableCell sx={{ width: '30%' }}>Aktívny do</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.tags.map((tag, index) => {
                                        return (
                                            <TableRow
                                                key={tag.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel control={
                                                        <Switch
                                                            checked={props.source.tags?.find(item => item.tagId === tag.id) !== undefined}
                                                            onChange={(e) => { handleChangeTag((tag?.id ?? 0), '', null, !e.target.checked) }}
                                                        />
                                                    }
                                                        label={tag.other?.translationName ?? ''} />
                                                </TableCell>
                                                <TableCell>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} value={NullMinDate(props.source.tags?.find(item => item.tagId === tag.id)?.validDateFrom)} onChange={(d) => { handleChangeTag((tag?.id ?? 0), 'validDateFrom', d, false, 'date') }} slotProps={{ textField: { size: 'small', fullWidth: true } }} />
                                                    </LocalizationProvider>
                                                </TableCell>
                                                <TableCell>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} value={NullMinDate(props.source.tags?.find(item => item.tagId === tag.id)?.validDateTo)} onChange={(d) => { handleChangeTag((tag?.id ?? 0), 'validDateTo', d, false, 'date') }} slotProps={{ textField: { size: 'small', fullWidth: true } }} />
                                                    </LocalizationProvider>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                    <Alert severity="info" sx={{ mt: 1 }}>
                        Akciová cena produktu je platná iba v čase, keď je aktívny štítok typu "akcia". Pokiaľ je nastavený čas aktívny od / aktívny do, tak je platnosť štítku ohraničená daným obdobím.
                    </Alert>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('codes')} onChange={() => { props.onToggleAccordion('codes') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Kódy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={3}>
                            <TextField margin="dense" name="ean" label="EAN" fullWidth variant="outlined" autoComplete="off" value={props.source.ean ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                inputRef={input => props.autoFocus === 'ean' && props.setFocus(input)}
                                inputProps={{ maxLength: 25 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Globálne obchodné číslo položky GTIN (Global Trade Item Number). Označenie je rôzne podľa regiónu alebo komodity: EAN (v Európe), UPC (v Severnej Amerike), JAN (v Japonsku), ISBN (pre knihy), ITF-14 (pre multipacky). Tento kód je bežne používaný vo forme čiarového kódu na obale tovaru.">
                                                <QrCodeIcon className="formIconHelp" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField margin="dense" name="codeSupplier" label="Kód dodávateľa" fullWidth variant="outlined" autoComplete="off" value={props.source.codeSupplier ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                inputRef={input => props.autoFocus === 'codeSupplier' && props.setFocus(input)}
                                inputProps={{ maxLength: 64 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TagIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField margin="dense" name="mpn" label="Kód výrobcu - MPN" fullWidth variant="outlined" autoComplete="off" value={props.source.mpn ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                inputRef={input => props.autoFocus === 'mpn' && props.setFocus(input)}
                                inputProps={{ maxLength: 25 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Kód výrobcu MPN (Manufacturer Part Number) alebo aj katalógové číslo, je pridelené samotným výrobcom. Používa sa na označenie konkrétneho produktu (dielu) medzi všetkými produktami od rovnakého výrobcu.">
                                                <TagIcon className="formIconHelp" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField margin="dense" name="model" label="Párovací kód modelu (zoskupenie)" fullWidth variant="outlined" autoComplete="off" value={props.source.model} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                inputRef={input => props.autoFocus === 'model' && props.setFocus(input)}
                                inputProps={{ maxLength: 64 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Jedinečné označenie modelu, podľa ktorého budú produkty zoskupené pre výber varianty.">
                                                <ExtensionIcon className="formIconHelp" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('domains')} onChange={() => { props.onToggleAccordion('domains') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Domény ({props.source.domains?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={12}>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel id="product-create-domain">Domény</InputLabel>
                                <Select multiple labelId="product-create-domain" label="Domény" name="domains" value={props.source.domains ?? []} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'domains' && props.setFocus(input)}>
                                    {props.domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Alert severity="warning">
                                Obmedzenia pre vybrané domény sú platné iba v rámci objednávkového systému pre zákazníka (eshop a podobne).
                                Obmedzenia nie sú uplatnené pri vytváraní dokladu cez administráciu (tu sú zobrazené všetky záznamy).
                                Pri obmedzení domény platí pravidlo, že ak je nie je označená žiadna možnosť, tak je produkt zobrazený na každej doméne.
                                Ak je v nastaveniach cieľovej domény aktívne prísne zobrazenie, tak daná doména zobrazí len tie produkty, ktoré majú označenú danú doménu.
                            </Alert>
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('categories')} onChange={() => { props.onToggleAccordion('categories') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Kategórie ({props.source.categories?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <ProductCreateCategory {...componentProps()} categories={props.categories} onCategoriesChanged={props.onCategoriesChanged} />

                        <Grid item xs={12} mt={1}>
                            <Alert severity="warning">
                                Produkt môže byť zaradený do viacerých kategórii. V administrácii sa používa iba hlavná kategória, alternatívne kategórie môžu byť použité v rámci objednávkového systému pre zákazníka (eshop a podobne).
                            </Alert>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('units')} onChange={() => { props.onToggleAccordion('units') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Merná jednotka</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={12} sm={3}>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel id="product-create-unit">Merná jednotka (predajná)</InputLabel>
                                <Select labelId="product-create-unit" label="Merná jednotka (predajná)" value={props.source.unitId ?? 0} name="unitId" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                    inputRef={input => props.autoFocus === 'unitId' && props.setFocus(input)}>
                                    <MenuItem value={0}>-</MenuItem>
                                    {props.units.map(unit => (<MenuItem key={unit.id} value={unit.id}>{unit.other?.translationName ?? ''}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField fullWidth margin="dense" name="infoUnitQuantity" type="text" label="Koeficient informačnej MJ" variant="outlined" autoComplete="off" value={props.source.infoUnitQuantity ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                inputRef={input => props.autoFocus === 'infoUnitQuantity' && props.setFocus(input)}
                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Množstvo v informačnej mernej jednotke vzhľadom na predajnú mernú jednotku. Napr. ak 1 ks (predajná MJ) má hmotnosť 0,5 kg (informačná MJ), tak koeficint bude: 0,5.">
                                                <CalculateIcon className="formIconHelp" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel id="product-create-unit-info">Merná jednotka (informačná)</InputLabel>
                                <Select labelId="product-create-unit-info" label="Merná jednotka (informačná)" value={props.source.infoUnitId ?? 0} name="infoUnitId" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                    inputRef={input => props.autoFocus === 'infoUnitId' && props.setFocus(input)}>
                                    <MenuItem value={0}>-</MenuItem>
                                    {props.units.map(unit => (<MenuItem key={unit.id} value={unit.id}>{unit.other?.translationName ?? ''}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField fullWidth margin="dense" name="weight" type="text" label="Hmotnosť MJ v gramoch" variant="outlined" autoComplete="off" value={props.source.weight ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                inputRef={input => props.autoFocus === 'weight' && props.setFocus(input)}
                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Je potrebné zadať hmotnosť jednej predajnej mernej jednotky v gramoch. Zadaná hmotnosť je použitá pre výpočet celkovej hmotnosti objednávky pre obmedzenie dopravy podľa nastaveného limitu od / do.">
                                                <ScaleIcon className="formIconHelp" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('sizes')} onChange={() => { props.onToggleAccordion('sizes') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Rozmer ({props.source.dimensionWidth ?? 0}x{props.source.dimensionHeight ?? 0}x{props.source.dimensionLength ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={12} sm={4}>
                            <TextField fullWidth margin="dense" name="dimensionWidth" type="text" label="Šírka (cm)" variant="outlined" autoComplete="off" value={props.source.dimensionWidth ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                inputRef={input => props.autoFocus === 'dimensionWidth' && props.setFocus(input)}
                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HeightIcon sx={{ transform: 'rotate(90deg)' }} />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField fullWidth margin="dense" name="dimensionHeight" type="text" label="Výška (cm)" variant="outlined" autoComplete="off" value={props.source.dimensionHeight ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                inputRef={input => props.autoFocus === 'dimensionHeight' && props.setFocus(input)}
                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HeightIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField fullWidth margin="dense" name="dimensionLength" type="text" label="Hĺbka, dĺžka (cm)" variant="outlined" autoComplete="off" value={props.source.dimensionLength ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                inputRef={input => props.autoFocus === 'dimensionLength' && props.setFocus(input)}
                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <OpenInFullIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('seo')} onChange={() => { props.onToggleAccordion('seo') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>SEO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabSeoValue} onChange={(e, v) => setTabSeoValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                            {props.languages.map((language, index) => <Tab key={language.id} value={index.toString()} label={language.code} />)}
                        </Tabs>
                    </Box>
                    <TabContext value={tabSeoValue}>
                        {props.languages.map((language, index) => {
                            return (
                                <TabPanel value={index.toString()} sx={{ px: '0', pb: 0, pt: 1 }} key={language.id}>
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={12}>
                                            <TextField required margin="dense" name="url" label={'URL (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && props.autoFocus === 'url' && props.setFocus(input)}
                                                value={props.source.translations?.find(item => item.languageId === language.id)?.url ?? ''}
                                                onChange={(e) => { props.onChangeTranslation((language?.id ?? 0), e.target.name, EscapeSlug(e.target.value)) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LinkIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField margin="dense" name='seoTitle' label={'Titulok stránky (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => props.autoFocus === 'seoTitle' && index === 0 && props.setFocus(input)}
                                                value={props.source.translations?.find(item => item.languageId === language.id)?.seoTitle ?? ''}
                                                onChange={(e) => { props.onChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <EditIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField fullWidth multiline rows={4} margin="dense" name="seoDescription" label={'Popis stránky (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                value={props.source.translations?.find(item => item.languageId === language.id)?.seoDescription ?? ''}
                                                onChange={(e) => { props.onChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputRef={input => index === 0 && props.autoFocus === 'seoDescription' && props.setFocus(input)}
                                                inputProps={{ maxLength: 500 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <EditIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            )
                        })}
                    </TabContext>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('limitations')} onChange={() => { props.onToggleAccordion('limitations') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Obmedzenia ({(props.source.customerGroups?.length ?? 0) + (props.source.shipments?.length ?? 0)})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel id="product-create-customergroups">Zákaznícke skupiny</InputLabel>
                                <Select multiple labelId="product-create-customergroups" label="Zákaznícke skupiny" name="customerGroups" value={props.source.customerGroups ?? []} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'customerGroups' && props.setFocus(input)}>
                                    {props.customerGroups.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel id="product-create-shipments">Dopravy</InputLabel>
                                <Select multiple labelId="product-create-shipments" label="Dopravy" name="shipments" value={props.source.shipments ?? []} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'shipments' && props.setFocus(input)}>
                                    {props.shipments.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Alert severity="warning">
                                Obmedzenia pre vybrané dopravy, zákaznícke skupiny a podobne sú platné iba v rámci objednávkového systému pre zákazníka (eshop a podobne).
                                Obmedzenia nie sú uplatnené pri vytváraní dokladu cez administráciu (tu je možné použiť akýkoľvek záznam).
                                Pri obmedzeniach platí pravidlo, že ak je nie je označená žiadna možnosť, tak záznam platí pre všetko.
                            </Alert>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('additional')} onChange={() => { props.onToggleAccordion('additional') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Doplnkové údaje</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={props.source.hideCart} name="hideCart" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Skryť košík" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={props.source.hideReview} name="hideReview" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Skryť hodnotenie" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={props.source.hidePrice} name="hidePrice" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Skryť cenu (a košík) všetkým" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={props.source.hidePriceUnlogged} name="hidePriceUnlogged" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Skryť cenu (a košík) neprihláseným" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={props.source.fixedPrice} name="fixedPrice" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Pevná základná cena (ignorovať import a pohyby)" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={props.source.fixedAvailability} name="fixedAvailability" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Pevná dostupnosť (ignorovať import a pohyby)" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControlLabel control={<Switch checked={props.source.fixedAll} name="fixedAll" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Ignorovať import (pevný produkt)" />
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={props.source.ordinalNumber ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToInt(e.target.value)) }}
                                inputRef={input => props.autoFocus === 'ordinalNumber' && props.setFocus(input)}
                                inputProps={{ min: 0, max: 32767, step: 1 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Poradové číslo slúži na odporúčané zoradenie produktov v zozname. Poradové číslo vyjadruje prioritu v poradí a preto sú produkty zoradené v poradí od najväčšieho čísla.">
                                                <SortIcon className="formIconHelp" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>

                        <Grid item xs={12} sm={9}>
                            <TextField margin="dense" name="www" label="Web výrobcu (URL)" fullWidth variant="outlined" autoComplete="off" value={props.source.www ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                inputRef={input => props.autoFocus === 'www' && props.setFocus(input)}
                                inputProps={{ maxLength: 255 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HttpIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('externals')} onChange={() => { props.onToggleAccordion('externals') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Externé katalógy ({props.source.externalCatalogs?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {((props.source?.externalCatalogs?.length ?? 0) > 0) && (
                        <Alert severity="info" sx={{ mb: 1 }}>
                            Pokiaľ produkt nemá nastavenú hodnotu CPC (Cost Per Click - poplatok za preklik), tak bude použitá hodnota nastavená v hlavnej kategórii produktu.
                            Pre niektoré katalógy je nevyhnutné, aby hlavná kategória produktu bola spárovaná s rovnakou kategóriou externého katalógu (nastavenia kategórie / katalógy).
                        </Alert>
                    )}

                    <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                        <div style={{ flexGrow: 1 }}>
                            <Table size="small" sx={{ minWidth: '500px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ width: '30%' }}>Hodnota CPC</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.externalCatalogs.map((externalCatalog, index) => {
                                        return (
                                            <TableRow
                                                key={externalCatalog.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel control={
                                                        <Switch
                                                            checked={props.source.externalCatalogs?.find(item => item.externalCatalogId === externalCatalog.id) !== undefined}
                                                            onChange={(e) => { handleChangeExternalCatalog((externalCatalog?.id ?? 0), '', null, !e.target.checked) }}
                                                        />
                                                    }
                                                        label={externalCatalog.name ?? ''} />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField name="cpc" type="text" size="small" fullWidth variant="outlined" autoComplete="off"
                                                        value={props.source.externalCatalogs?.find(item => item.externalCatalogId === externalCatalog.id)?.cpc ?? ''}
                                                        onChange={(e) => { handleChangeExternalCatalog((externalCatalog?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value), false) }}
                                                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="CPC (Cost Per Click) - maximálny poplatok za preklik.">
                                                                        <EuroIcon className="formIconHelp" />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <Alert severity="info" sx={{ mt: 1 }}>
                        Bezpečnostný token pre získanie XML: "<strong>{props.externalCatalogsToken}</strong>".
                    </Alert>
                </AccordionDetails>
            </Accordion>


            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('customfields')} onChange={() => { props.onToggleAccordion('customfields') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Vlastné polia</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>
                        <CustomFieldValues moduleId={Modules.Product} hideTitle={true} values={props.source.customFieldValues} languages={props.languages} useDefault={props.source.id === 0} onChange={customFieldValues => props.onChange('customFieldValues', customFieldValues)} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
};

export default ProductCreateBasic;
