import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Domain, ExportFilter } from '../../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../../utility/Number';

// Komponenty
import { Select, TextField, MenuItem, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import EuroIcon from '@mui/icons-material/Euro';
import { NullMinDate } from '../../../utility/Date';

export interface ExportCreateFilterDocumentsProps {
    filter: ExportFilter;
    onChange: (filter: ExportFilter) => void;
}

const ExportCreateFilterDocuments = (props: ExportCreateFilterDocumentsProps) => {

    // Stav
    const [domains, setDomains] = useState<Domain[]>([]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        props.onChange(({ ...props.filter, [property]: value }))
    }

    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            });
    };
    useEffect(() => { loadDomains(); }, []);

    return (
        <Grid container columnSpacing={1}>

            <Grid item xs={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="filter-actived">Aktívne</InputLabel>
                    <Select labelId="filter-actived" label="Aktívne" value={props.filter.actived ?? 0} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        <MenuItem value={0}>Všetko</MenuItem>
                        <MenuItem value={1}>Áno</MenuItem>
                        <MenuItem value={2}>Nie</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="filter-domain">Doména</InputLabel>
                    <Select labelId="filter-domain" label="Doména" value={props.filter.domainId ?? 0} name="domainId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        <MenuItem value={0}>Všetko</MenuItem>
                        <MenuItem value={-1}>Nezaradené</MenuItem>
                        {domains?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                    <FormControl margin="dense" fullWidth>
                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Vytvorené od" value={NullMinDate(props.filter.createdDateFrom)} onChange={(d) => { handleChange('createdDateFrom', d, 'date') }} />
                    </FormControl>
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                    <FormControl margin="dense" fullWidth>
                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Vytvorené do" value={NullMinDate(props.filter.createdDateTo)} onChange={(d) => { handleChange('createdDateTo', d, 'date') }} />
                    </FormControl>
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField fullWidth margin="dense" name="priceFrom" type="text" label="Celková suma od" variant="outlined" autoComplete="off" value={props.filter.priceFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EuroIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField fullWidth margin="dense" name="priceTo" type="text" label="Celková suma do" variant="outlined" autoComplete="off" value={props.filter.priceTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EuroIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

        </Grid>
    )
}

export default ExportCreateFilterDocuments;