import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { CustomerLite, CustomersFilter, Modules, User, UserRole } from '../../../models/Models';

// Utility
import { PasswordStrength, PasswordStrengthType } from '../../../utility/PasswordStrength';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, FormControlLabel, Tooltip, Box, Switch, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CustomFieldValues from '../custom_field/CustomFieldValues';
import CustomersDialog, { CustomersDialogProps } from '../../customer/CustomersDialog';

// Ikony
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import ContactsIcon from '@mui/icons-material/Contacts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): User => ({
    id: 0,
    userRoleId: 0,
    actived: true,
    name: '',
    email: '',
    password: '',
    note: ''
});

// Vstupné parametre
export interface UserCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const UserCreate = (props: UserCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<User>(EmptySource());
    const [sourcePasswordVerify, setSourcePasswordVerify] = useState<string | undefined>(undefined);
    const [sourceRole, setSourceRole] = useState<UserRole[]>([]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Výber existujúceho partnera
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[], argument: any) => {
            if (customers.length > 0) {
                setSource(prev => {
                    return ({
                        ...prev, customerId: customers[0].id ?? 0, other: {
                            ...prev.other,
                            customerName: (customers[0].person ?? false) === true ? (customers[0].firstname ?? '') + ' ' + (customers[0].lastname ?? '') : (customers[0].company ?? '')
                        }
                    });
                });
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });

    // Zobrazenie partnerov
    const handleCustomersOpen = (argument: any, filter: CustomersFilter) => {
        setCustomersDialog(prev => ({ ...prev, open: true, argument: argument, filter: filter }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        setSourcePasswordVerify('');
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'user/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as User);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam užívateľských rolí (načítam iba jeden krát po pripojení)
    const loadUserRoles = () => {
        axios
            .get(AppConfig.ApiUri + 'userrole')
            .then(response => {
                setSourceRole(response.data);
            });
    };
    useEffect(() => loadUserRoles(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if ((source.password?.length ?? 0) > 0) {
            const password = source.password ?? '';
            const passwordVerify = sourcePasswordVerify ?? '';

            if (password !== passwordVerify) {
                setWarning({ ...EmptyWarning, name: 'password', text: 'Heslá sa nezhodujú!' });
                return;
            }
            if (PasswordStrength(password) !== PasswordStrengthType.Strong &&
                PasswordStrength(password) !== PasswordStrengthType.Medium) {
                setWarning({ ...EmptyWarning, name: 'password', text: 'Heslo musí mať minimálne 8 znakov a obsahovať veľké písmeno, malé písmeno a číslicu!' });
                return;
            }
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'user', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Užívateľ' : 'Nový užívateľ')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            {(source.actived ?? false) === false && (
                                <Grid item xs={12}>
                                    <Alert severity="warning" sx={{ mb: 1 }}>
                                        Neaktívny užívateľ má zablokované prihlásenie do systému.
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6}>
                                <TextField required margin="dense" name="name" label="Meno" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 70 }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Meno a priezvisko užívateľa, pod ktorým bude užívateľ v systéme figurovať.">
                                                    <PersonIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="user-create-role">Užívateľská rola</InputLabel>
                                    <Select labelId="user-create-role" label="Užívateľská rola" value={source.userRoleId ?? 0} name="userRoleId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'user_role' && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>Žiadna</MenuItem>
                                        {sourceRole?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required fullWidth margin="dense" name="email" type="email" label="Email" variant="outlined" autoComplete="email" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 120 }}
                                    inputRef={input => { props.autoFocus === 'email' && setFocus(input) }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Email slúži na prihlásenie užívateľa a súčasne aj ako kontaktný údaj pre notifikácie.">
                                                    <EmailIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" error={warning.name === 'password'} name="password" type="password" label="Nové heslo" fullWidth variant="outlined" autoComplete="new-password" value={source.password ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputProps={{ maxLength: 70 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Možnosť zmeny prihlasovacieho hesla">
                                                    <LockIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" error={warning.name === 'password'} name="passwordverify" type="password" label="Nové heslo znovu" fullWidth variant="outlined" autoComplete="new-password" value={sourcePasswordVerify ?? ''} onChange={(e => { setSourcePasswordVerify(e.target.value); })}
                                    inputProps={{ maxLength: 70 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Overenie správnosti nového hesla. Je potrebné zopakovať nové heslo.">
                                                    <LockIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={2}>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('customer')} onChange={() => handleToggleAccordion('customer')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Adresár ({((source.customerId ?? 0) > 0 ? (source.other?.customerName ?? '') : 'nie')})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField margin="dense" label="Vybraný partner" fullWidth variant="outlined" autoComplete="off" disabled onClick={() => handleCustomersOpen('', { person: 1 })}
                                                    value={((source.customerId ?? 0) > 0 ? (source.other?.customerName ?? '') : '...')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Prepojenie na záznam v adresári, ktorý obsahuje doplnkové údaje napríklad pre potrebu fakturácie a podobne.">
                                                                    <PersonIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12} mb={1}>
                                                <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => handleCustomersOpen('', { person: 1 })}>Vybrať</Button>
                                                {(source.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => { setSource(prev => ({ ...prev, customerId: 0 })) }}>Zrušiť prepojenie</Button>}
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Interná poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                            </Grid>

                            <CustomFieldValues moduleId={Modules.User} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>

                {/* Výber z adresára (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <CustomersDialog {...customersDialog} />
            </Dialog>
        </>
    )
}

export default UserCreate;