import React, { useState } from 'react';

// Modely
import { File, ProductFile } from '../../models/Models';
import { ProductCreateComponentProps } from './ProductCreate';

// Utility
import { FileOpen } from '../../utility/File';

// Komponenty
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, FormControlLabel, IconButton, ListItemIcon, Menu, MenuItem, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FilesDialog, { FilesDialogProps } from '../file/FilesDialog';
import ProductCreateFilesEdit, { ProductCreateFilesEditProps } from './ProductCreateFilesEdit';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Vstupné parametre, vychádzam zo spoločného predpisu
export interface ProductCreateFilesProps extends ProductCreateComponentProps {

};

const ProductCreateFiles = (props: ProductCreateFilesProps) => {

    // Stav
    const [productCreateFilesEdit, setProductCreateFilesEdit] = useState<ProductCreateFilesEditProps>({
        open: false,
        keepMounted: true,
        file: {},
        languages: props.languages,
        onSave: (file: ProductFile) => {
            props.setSource(prev => {
                const changed = [...prev?.files ?? []];
                changed?.every((item, itemIndex) => {
                    if (item.type === file.type && item.fileId === file.fileId) {
                        changed[itemIndex] = file;
                        return false;
                    }
                    return true;
                });
                return { ...prev, files: changed };
            });
        },
        onClose: () => handleFilesEditClose()
    });
    const handleFilesEditOpen = (file: ProductFile) => {
        setProductCreateFilesEdit(prev => ({ ...prev, open: true, file: file }));
    };
    const handleFilesEditClose = () => {
        setProductCreateFilesEdit(prev => ({ ...prev, open: false }));
    };

    // Okno súborov
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        watermark: true,
        directoryType: 1, // Predvolený adresár pre produkty
        onSelect: (files: File[], argument: any) => {
            if (files.length > 0) {
                props.setSource(prev => {
                    let changed = prev.files ?? [];
                    const languageId = props.languages.find(language => language.system)?.id;
                    files.forEach(file => {
                        // Súbor type=0 musí byť obrázok, všetko ostatné môže byť akýkoľvek súbor
                        if (argument !== 0 || file.fileIsImage) {
                            if (prev.files?.find(item => item.type === argument && item.fileId === file.id) === undefined) {
                                const ordinalNumber = ((changed.filter(item => item.type === argument)?.length ?? 0) > 0 ? (Math.max(...(changed.filter(item => item.type === argument)?.map(i => i.ordinalNumber ?? 0) ?? [])) ?? 0) + 1 : 0);
                                changed.push({
                                    type: argument as number,
                                    fileId: file.id,
                                    ordinalNumber: ordinalNumber,
                                    coverInList: ordinalNumber === 0 && argument === 0,
                                    coverInDetail: ordinalNumber === 0 && argument === 0,
                                    translations: (languageId !== undefined ? [{ languageId: languageId, name: file.name }] : []),
                                    other: {
                                        translationName: (languageId !== undefined ? file.name : ''),
                                        fileExtension: file.fileExtension,
                                        fileIsImage: file.fileIsImage,
                                        fileName: file.fileName,
                                        fileNameSmall: file.fileNameSmall,
                                        fileNameMedium: file.fileNameMedium,
                                        fileSrc: file.fileSrc,
                                        fileSrcSmall: file.fileSrcSmall,
                                        fileSrcMedium: file.fileSrcMedium
                                    }
                                });
                            }
                        }
                    });
                    return { ...prev, files: changed };
                });
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Kontextové menu
    const [filesMenuItem, setFilesMenuItem] = useState<ProductFile>();
    const [filesMenuEl, setFilesMenuEl] = useState<null | HTMLElement>(null);

    // Funkcia pre otvorenie kontexového menu zložky
    const handleFilesMenuOpen = (e: HTMLElement, file: ProductFile) => {
        setFilesMenuItem(file);
        setFilesMenuEl(e);
    };

    // Zobrazenie súborov
    const handleFilesOpen = (type: number) => {
        setFilesDialog(prev => ({ ...prev, open: true, argument: type }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Po dvoj-kliku otvorím súbor
    const handleFileClick = (file: ProductFile) => {
        if (file?.other?.fileSrc !== undefined) {
            FileOpen(file?.other?.fileSrc, '_blank');
        }
    };

    // Vymazať súbor 
    const handleFileDelete = (file: ProductFile) => {
        props.setSource(prev => {
            let changed = (prev.files ?? [])?.filter(item => !(item.type === file.type && item.fileId === file.fileId));
            return { ...prev, files: changed };
        });
    }

    // Upraviť súbor
    const handleFileChange = (id: number, type: number, property: string, value: any) => {
        props.setSource(prev => {
            const changed = [...prev?.files ?? []];

            // Získam index položky
            let index = 0;
            changed?.every((item, itemIndex) => {
                if (item.type === type && item.fileId === id) {
                    index = itemIndex;
                    return false;
                }
                return true;
            });

            // Upravím položku
            changed[index] = { ...changed[index], [property]: value };

            // Prepínač - ak som označil obrázok ako titulný v detaile alebo v zozname, tak všetky ostatné odznačím
            if (type === 0 && value === true && (property === 'coverInList' || property === 'coverInDetail')) {
                changed?.every((item, itemIndex) => {
                    if (itemIndex !== index && changed[itemIndex].type === type) {
                        changed[itemIndex] = { ...changed[itemIndex], [property]: false };
                    }
                    return true;
                });
            }

            return { ...prev, files: changed };
        });
    };

    // Zoradenie položiek, najskôr podľa poradia potom podľa id súboru (poradie môže byť rovnaké)
    const filesSortComparer = (a: ProductFile, b: ProductFile): number => {
        if ((a.ordinalNumber ?? 0) !== (b.ordinalNumber ?? 0)) {
            return (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1;
        }
        else {
            return (a.fileId ?? 0) > (b.fileId ?? 0) ? 1 : -1;
        }
    }

    return (
        <>
            {/* Kontextové menu položky */}
            <Menu id="menu-directories" anchorEl={filesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(filesMenuEl)} onClose={() => setFilesMenuEl(null)} >
                <MenuItem onClick={() => { if (filesMenuItem !== undefined && filesMenuItem?.other?.fileSrc !== undefined) { FileOpen(filesMenuItem?.other?.fileSrc, '_blank'); } setFilesMenuEl(null); }}>
                    <ListItemIcon><SearchIcon fontSize="small" /></ListItemIcon> Otvoriť súbor
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { handleFilesEditOpen(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť súbor
                </MenuItem>
                <MenuItem onClick={() => { handleFileDelete(filesMenuItem ?? {}); setFilesMenuEl(null); }}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať súbor
                </MenuItem>
            </Menu>



            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('images')} onChange={() => { props.onToggleAccordion('images') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Obrázky ({props.source.files?.filter(file => file.type === 0)?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button color="secondary" size="small" variant="contained" sx={{ mb: 1 }} onClick={() => handleFilesOpen(0)}>Vložiť nový</Button>
                    <Grid container columnSpacing={2} rowSpacing={2} {...(props.source.files?.filter(file => file.type === 0)?.length === 0 && { display: "none" })}>
                        {props.source.files?.filter(file => file.type === 0)?.sort((a, b) => filesSortComparer(a, b))?.map((file) => (
                            <Grid key={file.fileId} item xs={6} sm={3} md={4} lg={3} xl={2}>
                                <Card>
                                    <CardActionArea onClick={() => handleFileClick(file)}>
                                        <CardMedia component="img" image={(file?.other?.fileSrcMedium ?? '')} alt={file.other?.translationName ?? ''} sx={{ height: { xs: '100px', md: '150px' } }} />
                                    </CardActionArea>
                                    <CardContent sx={{ pb: 0 }}>
                                        <FormControlLabel control={<Switch checked={file.coverInList ?? false} size="small" name="coverInList" onChange={(e) => { handleFileChange(file.fileId ?? 0, file.type ?? 0, e.target.name, e.target.checked) }} />} label={<Typography noWrap fontSize="small">Zoznam (hlavný)</Typography>} />
                                        <FormControlLabel control={<Switch checked={file.coverInDetail ?? false} size="small" name="coverInDetail" onChange={(e) => { handleFileChange(file.fileId ?? 0, file.type ?? 0, e.target.name, e.target.checked) }} />} label={<Typography noWrap fontSize="small">Detail (hlavný)</Typography>} />
                                    </CardContent>
                                    <CardActions>
                                        <Typography noWrap fontSize="small" pl={1}>{'#' + (file.ordinalNumber ?? 0) + ' - ' + ((file.other?.translationName?.length ?? 0) > 0 ? file.other?.translationName : 'Bez názvu')}</Typography>
                                        <IconButton sx={{ marginLeft: 'auto' }} onClick={(e) => handleFilesMenuOpen(e.currentTarget, file)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>



            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('files')} onChange={() => { props.onToggleAccordion('files') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Na stiahnutie ({props.source.files?.filter(file => file.type === 1)?.length ?? 0})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button color="secondary" size="small" variant="contained" sx={{ mb: 1 }} onClick={() => handleFilesOpen(1)}>Vložiť nový</Button>
                    <Grid container columnSpacing={2} rowSpacing={2} {...(props.source.files?.filter(file => file.type === 1)?.length === 0 && { display: "none" })}>
                        {props.source.files?.filter(file => file.type === 1)?.sort((a, b) => filesSortComparer(a, b))?.map((file) => (
                            <Grid key={file.fileId} item xs={6} sm={3} md={4} lg={3} xl={2}>
                                <Card>
                                    <CardActionArea onClick={() => handleFileClick(file)}>
                                        {file?.other?.fileIsImage ? (
                                            <CardMedia component="img" image={(file?.other?.fileSrcMedium ?? '')} alt={file.other?.translationName ?? ''} sx={{ height: { xs: '100px', md: '150px' } }} />
                                        ) : (
                                            <CardMedia component="div" sx={{ display: 'flex', height: { xs: '100px', md: '150px' }, justifyContent: 'center', alignItems: 'center' }}>
                                                <InsertDriveFileOutlinedIcon fontSize="large" />
                                            </CardMedia>
                                        )}
                                    </CardActionArea>
                                    <CardActions>
                                        <Typography noWrap fontSize="small" pl={1}>{'#' + (file.ordinalNumber ?? 0) + ' - ' + ((file.other?.translationName?.length ?? 0) > 0 ? file.other?.translationName : 'Bez názvu')}</Typography>
                                        <IconButton sx={{ marginLeft: 'auto' }} onClick={(e) => handleFilesMenuOpen(e.currentTarget, file)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <FilesDialog {...filesDialog} />

            <ProductCreateFilesEdit {...productCreateFilesEdit} />
        </>
    )
};

export default ProductCreateFiles;