import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../../AppRoutes';

// Utility
import { ConvertToInt } from '../../../utility/Number';

// Modely
import { Branch, CustomerLite, CustomersFilter, Modules } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import CustomersDialog, { CustomersDialogProps } from '../../customer/CustomersDialog';
import CustomFieldValues from '../custom_field/CustomFieldValues';

// Ikony
import ApartmentIcon from '@mui/icons-material/Apartment';
import EditIcon from '@mui/icons-material/Edit';
import SortIcon from '@mui/icons-material/Sort';
import ContactsIcon from '@mui/icons-material/Contacts';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Branch => ({
    id: 0,
    actived: true,
    ordinalNumber: 0,
    name: '',
    note: ''
});

// Vstupné parametre
export interface BranchCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const BranchCreate = (props: BranchCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Branch>(EmptySource());
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Výber existujúceho partnera
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[], argument: any) => {
            if (customers.length > 0) {
                setSource(prev => {
                    return ({
                        ...prev, customerId: customers[0].id ?? 0, customerName: (customers[0].person ?? false) === true ? (customers[0].firstname ?? '') + ' ' + (customers[0].lastname ?? '') : (customers[0].company ?? '')
                    });
                });
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });

    // Zobrazenie partnerov
    const handleCustomersOpen = (argument: any, filter: CustomersFilter) => {
        setCustomersDialog(prev => ({ ...prev, open: true, argument: argument, filter: filter }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'branch/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Branch);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.customerId === 0) {
            setWarning({ ...EmptyWarning, name: 'customerId', text: 'Musíte vybrať účtovnú jednotku prevádzky!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'branch', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Prevádzka (pobočka)' : 'Nová prevádzka (pobočka)')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} md={8} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ApartmentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" label="Účtovná jednotka" fullWidth variant="outlined" autoComplete="off" disabled required
                                    value={((source.customerId ?? 0) > 0 ? (source.customerName ?? '') : '...')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mb={1}>
                                <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => handleCustomersOpen('', { person: 2 })}>Vybrať</Button>
                                {(source.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (source.customerId ?? 0).toString()} target="_blank">Detail</Button>}
                                {(source.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => { setSource(prev => ({ ...prev, customerId: 0 })) }}>Zrušiť prepojenie</Button>}
                            </Grid>

                            <Grid item xs={12} mb={1}>
                                <Alert severity='warning'>
                                    <strong>Skladové hospodárstvo je spoločné pre všetky prevádzky</strong> a nezohľadňuje nastavenie rôznych účtovných jednotiek pre oceňovanie zásob (VNC, PSC), výpočet zostatkov (zásoby) a podobne.
                                    Pre oddelené skladové hospodárstvo je nutné používať oddelenú databázu (samostatnú licenciu) pre každú účtovnú jednotku.
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <CustomFieldValues moduleId={Modules.Branch} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>

                {/* Výber z adresára (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <CustomersDialog {...customersDialog} />
            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />
        </>
    )
}

export default BranchCreate;