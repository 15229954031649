import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../../utility/Number';

// Modely
import { Branch, Department, Modules } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, FormControl, InputLabel, MenuItem, Select, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import CustomFieldValues from '../custom_field/CustomFieldValues';

// Ikony
import ApartmentIcon from '@mui/icons-material/Apartment';
import EditIcon from '@mui/icons-material/Edit';
import SortIcon from '@mui/icons-material/Sort';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Department => ({
    id: 0,
    actived: true,
    ordinalNumber: 0,
    name: '',
    note: ''
});

// Vstupné parametre
export interface DepartmentCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const DepartmentCreate = (props: DepartmentCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Department>(EmptySource());
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [branches, setBranches] = useState<Branch[]>([]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Načítam číselník
    const loadBranches = () => {
        axios
            .get(AppConfig.ApiUri + 'branch')
            .then(response => {
                setBranches((response.data as Branch[]).filter(item => item.actived));
            });
    };
    useEffect(() => loadBranches(), []);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'department/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Department);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.branchId === 0) {
            setWarning({ ...EmptyWarning, name: 'branchId', text: 'Musíte vybrať prevádzku!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'department', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Stredisko (oddelenie)' : 'Nové stredisko (oddelenie)')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} md={8} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ApartmentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth required>
                                    <InputLabel id="department-create-branch">Prevádzka (pobočka)</InputLabel>
                                    <Select labelId="department-create-branch" label="Prevádzka (pobočka)" value={source.branchId ?? 0} name="branchId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'branchId' || props.autoFocus === 'branchName') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {branches?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                        <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <CustomFieldValues moduleId={Modules.Department} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />
        </>
    )
}

export default DepartmentCreate;