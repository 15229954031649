import React, { useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { CustomField, CustomFieldValue, Language } from '../../../models/Models';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';

// Ikony
import EditIcon from '@mui/icons-material/Edit';

interface CustomFieldValuesProps {
    moduleId: number; // Podľa číselníku "item_typ"
    languages?: Language[];
    values?: CustomFieldValue[];
    useDefault?: boolean;
    hideTitle?: boolean;
    onChange: (values: CustomFieldValue[], requiredCompleted?: boolean) => void;
}

const CustomFieldValues = (props: CustomFieldValuesProps) => {

    // Stav
    const [customFields, setCustomFields] = useState<CustomField[]>([]);

    // Funkcia pre načítanie dát z API
    const loadCustomFields = useCallback(() => {
        if ((props.moduleId ?? 0) === 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'customfield/type/' + props.moduleId)
            .then(response => {
                if (response.data !== null) {
                    setCustomFields(response.data.filter((item: CustomField) => item.actived));
                }
            });
    }, [props.moduleId]);

    // Načítam dáta po zobrazení
    useEffect(() => loadCustomFields(), [loadCustomFields]);

    // Po zmene automaticky doplním predvolené hodnoty 
    useEffect(() => {
        // Ak mám použiť predvolené hodnoty a zoznam hodnôt je ešte prázdny a súčasne existujú nejaké defaultné hodnoty, tak ich použijem
        if ((props.useDefault ?? false) && (props.values ?? []).length === 0 && customFields.filter(field => (field.translations?.filter(t => (t.default?.length ?? 0) > 0).length ?? 0) > 0).length > 0) {
            const values: CustomFieldValue[] = [];
            customFields.forEach(item => {
                item.translations?.forEach(translation => {
                    if((translation.default ?? '').length > 0) {
                        values.push({
                            customFieldId: item.id,
                            languageId: (item.languages === true ? translation.languageId : 0),
                            value: translation.default
                        })
                    }
                });
            });
            props.onChange(values, isRequiredCompleted(values));
        }
    }, [customFields, props.values, props.useDefault]); // eslint-disable-line react-hooks/exhaustive-deps

    // Overí, či všetky povinné polia obsahujú vyplnené hodnoty
    const isRequiredCompleted = (values: CustomFieldValue[]): boolean => {
        // Každé povinné pole musí byť nájdené naplnené jeden krát (ak nie sú aktívne jazyky) alebo toľko krát koľko je jazykov (ak sú aktívne jazyky)
        if (customFields?.filter(field => field.required)?.find(field =>
            // Ak nie je splnená ani jedna podmienka, znamená to, že hodnoty chýbajú
            !(
                // Ak sú aktívne jazyky, tak počet naplnených hodnôt pre dané pole musí byť rovnaký ako počet jazykov
                (field.languages === true && values.filter(value => (value.value ?? '').length > 0 && value.customFieldId === field.id)?.length === props.languages?.length)
                ||
                // Ak nie sú aktívne jazyky, tak počet naplnených hodnôt pre dané pole musí byť väčší ako nula
                (field.languages === false && values.filter(value => (value.value ?? '').length > 0 && value.customFieldId === field.id)?.length > 0)
            )
        )) {
            return false;
        }
        return true;
    };

    // Zmeny vo formulári
    const handleChange = (customFieldId: number, languageId: number, value: any) => {
        const item: CustomFieldValue = {
            ...props.values?.find(item => item.customFieldId === customFieldId && item.languageId === languageId),
            customFieldId: customFieldId,
            languageId: languageId,
            value: value
        };
        const values = [...props.values?.filter(item => !(item.customFieldId === customFieldId && item.languageId === languageId)) ?? [], item];
        props.onChange(values, isRequiredCompleted(values));
    }

    // Položka formuláru
    const renderField = (key: any, field: CustomField, languageId?: number, languageCode?: string) => {

        // Popisok políčka a aktuálna hodnota
        let fieldLabel = (field.other?.translationName ?? '');
        let fieldValue = props.values?.find(value => value.customFieldId === field.id && value.languageId === (languageId ?? 0))?.value;

        // Pri zobrazení viacerých jazkoch pridám označenie jazyka
        if ((languageId ?? 0) > 0) {
            fieldLabel += ' (' + languageCode + ')';
        }

        return (
            <Grid key={key} item xs={12}>
                <TextField
                    required={field.required} margin='dense' name='name' fullWidth variant='outlined' autoComplete='off'
                    label={fieldLabel ?? ''}
                    value={fieldValue ?? ''}
                    onChange={(e) => { handleChange((field?.id ?? 0), (languageId ?? 0), e.target.value) }}
                    inputProps={{ maxLength: 255 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EditIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>
        )
    };

    if (customFields.length === 0) {
        return null;
    }
    return (
        <React.Fragment>

            {(props.hideTitle ?? false) === false && (
                <Grid item xs={12} mt={3}>
                    <Typography textAlign="center" variant="h6" gutterBottom>Vlastné polia</Typography>
                </Grid>
            )}

            {/* Zoznam na miestach kde sa nepoužívajú jazyky */}
            {(props.languages?.length ?? 0) === 0 && (
                customFields.map((field, index) => renderField(index, field))
            )}

            {/* Zoznam na miestach kde sa používajú jazyky */}
            {(props.languages?.length ?? 0) > 0 && (
                customFields?.map((field, index) => props.languages?.map((language, index2) => {

                    // Zložím unikátny kľúč
                    const key = index + '-' + index2;

                    // Ak toto pole nepoužíva jazyky, tak zobrazím len prvý a ostatné preskočím
                    if (!field.languages && index2 > 0) {
                        return null;
                    }

                    // Ak toto pole nepoužíva jazyky, tak ho zobrazím bez označenia jazyka
                    if (!field.languages) {
                        return renderField(key, field);
                    }

                    // Políčko pre daný jazyk
                    return renderField(key, field, language.id, language.code);
                }
                ))
            )}

        </React.Fragment>
    )
}

export default CustomFieldValues;