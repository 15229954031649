import { Editor as TinyMCEEditor } from 'tinymce';

enum TargetPlaces {
    Into,
    Before,
    After
}

export const addExtraToolbar = (editor: TinyMCEEditor) => {
    editor.ui.registry.addMenuButton('extra', {
        text: 'Extra',
        icon: 'browse',
        fetch: (callback) => {
            const items = [
                {
                    type: 'menuitem',
                    icon: 'table-cell-properties',
                    text: 'Vložiť blok zo šablóny',
                    onAction: () => editor.execCommand('mceTemplate')
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Vložiť harmoniku',
                    icon: 'flip-vertically',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Pred blok',
                            icon: 'table-insert-row-above',
                            onAction: () => editor.undoManager.transact(() => addAccordion(editor, TargetPlaces.Before)),
                        },
                        {
                            type: 'menuitem',
                            text: 'Do bloku',
                            icon: 'table-insert-column-after',
                            onAction: () => editor.undoManager.transact(() => addAccordion(editor, TargetPlaces.Into)),
                        },
                        {
                            type: 'menuitem',
                            text: 'Za blok',
                            icon: 'table-insert-row-after',
                            onAction: () => editor.undoManager.transact(() => addAccordion(editor, TargetPlaces.After)),
                        },
                    ]
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Vložiť prázdny odstavec',
                    icon: 'flip-vertically',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Pred blok',
                            icon: 'table-insert-row-above',
                            onAction: () => editor.undoManager.transact(() => addParagraph(editor, TargetPlaces.Before)),
                        },
                        {
                            type: 'menuitem',
                            text: 'Do bloku',
                            icon: 'table-insert-column-after',
                            onAction: () => editor.undoManager.transact(() => addParagraph(editor, TargetPlaces.Into)),
                        },
                        {
                            type: 'menuitem',
                            text: 'Za blok',
                            icon: 'table-insert-row-after',
                            onAction: () => editor.undoManager.transact(() => addParagraph(editor, TargetPlaces.After)),
                        },
                    ]
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Vložiť prázdny div',
                    icon: 'flip-vertically',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            text: 'Pred blok',
                            icon: 'table-insert-row-above',
                            onAction: () => editor.undoManager.transact(() => addDiv(editor, TargetPlaces.Before)),
                        },
                        {
                            type: 'menuitem',
                            text: 'Do bloku',
                            icon: 'table-insert-column-after',
                            onAction: () => editor.undoManager.transact(() => addDiv(editor, TargetPlaces.Into)),
                        },
                        {
                            type: 'menuitem',
                            text: 'Za blok',
                            icon: 'table-insert-row-after',
                            onAction: () => editor.undoManager.transact(() => addDiv(editor, TargetPlaces.After)),
                        },
                    ]
                },
                {
                    type: 'separator'
                },
                {
                    type: 'nestedmenuitem',
                    text: 'Funkcie',
                    icon: 'preferences',
                    getSubmenuItems: () => [
                        {
                            type: 'menuitem',
                            icon: 'image',
                            text: 'Nastaviť obrázok pozadia',
                            onAction: () => addBackgroundImage(editor, true), // "undo" musí byť vo funkcii kvôli callbacku
                        },
                        {
                            type: 'menuitem',
                            icon: 'table-delete-table',
                            text: 'Odstrániť obrázok pozadia',
                            onAction: () => addBackgroundImage(editor, false), // "undo" musí byť vo funkcii kvôli callbacku
                        },
                        {
                            type: 'separator'
                        },
                        {
                            type: 'menuitem',
                            icon: 'edit-block',
                            text: 'Nastaviť triedy štýlu',
                            onAction: () => editor.undoManager.transact(() => addClassName(editor)),
                        },
                        {
                            type: 'menuitem',
                            icon: 'select-all',
                            text: 'Nastaviť odsadenie',
                            onAction: () => editor.undoManager.transact(() => addPadding(editor)),
                        },
                        {
                            type: 'separator'
                        },
                        {
                            type: 'menuitem',
                            text: 'Odstrániť celý blok',
                            icon: 'table-delete-table',
                            onAction: () => editor.undoManager.transact(() => removeBlock(editor)),
                        },
                    ]
                },
            ];
            callback(items as any);
        }
    });
};

export const addAccordion = (editor: TinyMCEEditor, targetPlace: TargetPlaces) => {
    const newNode = editor.dom.create('div', { 'class': 'mce-accordion active' }, `
        <h3 onclick="this.parentElement.classList.toggle('active')">Section</h3>
        <div><p>Content for section.</p></div>
    `);
    const activeElement = editor.selection.getNode();
    if (activeElement.tagName.toLowerCase() === 'body') {
        targetPlace = TargetPlaces.Into;
    }
    if (activeElement.tagName.toLowerCase() === 'p') {
        targetPlace = TargetPlaces.After;
    }
    if (targetPlace === TargetPlaces.Into) {
        editor.selection.setNode(newNode);
        return;
    }
    else if (targetPlace === TargetPlaces.Before && activeElement.parentNode) {
        activeElement.parentNode.insertBefore(newNode, activeElement);
    }
    else if (targetPlace === TargetPlaces.After && activeElement.parentNode) {
        editor.dom.insertAfter(newNode, activeElement);
    }
    editor.focus();
    editor.selection.setCursorLocation(newNode, 0);
};


export const addDiv = (editor: TinyMCEEditor, targetPlace: TargetPlaces) => {
    const className = window.prompt('Trieda štýlu (voliteľné):');
    if (className === null) {
        return;
    }
    const newNode = editor.dom.create('div', className.length > 0 ? { 'class': className } : {}, '&nbsp;');
    const activeElement = editor.selection.getNode();
    if (activeElement.tagName.toLowerCase() === 'body') {
        targetPlace = TargetPlaces.Into;
    }
    if (activeElement.tagName.toLowerCase() === 'p') {
        targetPlace = TargetPlaces.After;
    }
    if (targetPlace === TargetPlaces.Into) {
        editor.selection.setNode(newNode);
        return;
    }
    else if (targetPlace === TargetPlaces.Before && activeElement.parentNode) {
        activeElement.parentNode.insertBefore(newNode, activeElement);
    }
    else if (targetPlace === TargetPlaces.After && activeElement.parentNode) {
        editor.dom.insertAfter(newNode, activeElement);
    }
    editor.focus();
    editor.selection.setCursorLocation(newNode, 0);
};

export const addParagraph = (editor: TinyMCEEditor, targetPlace: TargetPlaces) => {
    const newNode = editor.dom.create('p', {}, '&nbsp;');
    const activeElement = editor.selection.getNode();
    if (activeElement.tagName.toLowerCase() === 'body') {
        targetPlace = TargetPlaces.Into;
    }
    if (targetPlace === TargetPlaces.Into) {
        editor.selection.setNode(newNode);
        return;
    }
    else if (targetPlace === TargetPlaces.Before && activeElement.parentNode) {
        activeElement.parentNode.insertBefore(newNode, activeElement);
    }
    else if (targetPlace === TargetPlaces.After && activeElement.parentNode) {
        editor.dom.insertAfter(newNode, activeElement);
    }
    editor.focus();
    editor.selection.setCursorLocation(newNode, 0);
};

export const addClassName = (editor: TinyMCEEditor) => {
    let activeElement = editor.selection.getNode();
    if (activeElement.tagName.toLowerCase() === 'body') {
        return;
    }
    let currentClass = activeElement.getAttribute('class') || '';
    const className = window.prompt('Trieda štýlu:', currentClass.trim());
    if (className === null) {
        return;
    }
    if (className.trim() === '') {
        activeElement.removeAttribute('class');
    } else {
        activeElement.setAttribute('class', className.trim());
    }
};

export const addPadding = (editor: TinyMCEEditor) => {
    let activeElement = editor.selection.getNode();
    if (activeElement.tagName.toLowerCase() === 'body') {
        return;
    }
    const currentPadding = editor.dom.getStyle(activeElement, 'padding');
    const newPadding = window.prompt('Odsadenie (napr.: 10px):', currentPadding);
    if (newPadding === null) {
        return;
    }
    editor.dom.setStyle(activeElement, 'padding', (newPadding.length > 0 ? newPadding : null));
    editor.focus();
    editor.selection.setCursorLocation(activeElement, 0);
};

export const addBackgroundImage = (editor: TinyMCEEditor, add: boolean) => {
    let activeElement = editor.selection.getNode();
    if (activeElement.tagName.toLowerCase() === 'body') {
        return;
    }
    const applyBackground = (url: string | null, meta: any) => {
        editor.undoManager.transact(() => {
            editor.dom.setStyle(activeElement, 'background-image', (url !== null ? `url(${url})` : null));
            editor.dom.setStyle(activeElement, 'background-size', (url !== null ? 'cover' : null));
            editor.dom.setStyle(activeElement, 'background-position', (url !== null ? 'center' : null));
            editor.focus();
            editor.selection.setCursorLocation(activeElement, 0);
        });
    }
    if (add === false) {
        applyBackground(null, null);
        return;
    }
    const filePickerCallback = editor.settings.file_picker_callback;
    if (filePickerCallback) {
        filePickerCallback(applyBackground, '', { filetype: 'image' });
    } else {
        console.error('file_picker_callback is not defined');
    }
};

export const removeBlock = (editor: TinyMCEEditor) => {
    const activeElement = editor.selection.getNode();
    if (activeElement.tagName.toLowerCase() === 'body') {
        return;
    }
    let parentNode: Element = activeElement;
    while (parentNode.parentNode && parentNode.parentNode !== editor.getBody()) {
        parentNode = parentNode.parentNode as Element;
    }
    editor.dom.remove(parentNode);
};
