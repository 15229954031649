// CSS štýly, ktoré sú použité v obsahu zobrazenom na strane klienta
// Tieto štýly je potrebné aplikovať na všetky miesta, kde je zobrazený obsah vygenerovaný editorom: 
// - Cieľový web
// - Iframe s náhľadom
// - Tlačové zostavy
export const TinyMCEStyleClient: string =
    `
    .mce-accordion { display: block; margin: 5px 0; }
    .mce-accordion > h3 { background-color: #f1f1f1; border: 1px solid #ddd; padding: 10px; text-align: left; cursor: pointer; margin: 0; }
    .mce-accordion > h3:hover { background-color: #f6f6f6; }
    .mce-accordion > div { border: 1px solid #ddd; border-top: none; padding: 5px 10px; display: none; }
    .mce-accordion.active > div { display: block; }

    .mce-container { display: flex; flex-wrap: wrap; gap: 5px; box-sizing: border-box; }
    .mce-container > .column { flex: 1; flex-grow: 1; min-width: 10px; box-sizing: border-box; }
    .mce-container.nogap  { gap: 0px; }
    .mce-container.w200 > .column { min-width: 200px; }
    .mce-container.w400 > .column { min-width: 400px; }

    .mce-container.third > .column:first-of-type { flex-grow: 2; }
    .mce-container.third.w200 > .column:first-of-type { min-width: 400px; }
    .mce-container.third.w400 > .column:first-of-type { min-width: 600px; }

    .mce-container.third-end > .column:last-of-type { flex-grow: 2; }
    .mce-container.third-end.w200 > .column:last-of-type { min-width: 400px; }
    .mce-container.third-end.w400 > .column:last-of-type { min-width: 600px; }

    .mce-img-gallery { display: inline-block; vertical-align: text-bottom; width: 100%; max-width: 200px !important; height: auto !important; aspect-ratio: 1/1; object-fit: cover; object-position: center center; margin: 0 20px 20px 0; }
    .mce-img-responsive { max-width: 100%!important; height: auto; }
    .mce-img-rounded { border-radius: 10px; }
    .mce-img-primary { border: solid 5px #00000080; }
    .mce-img-secondary { border: solid 5px #ffffff80; }
    
    .mce-btn,
    .mce-btn-lg, 
    .mce-btn-sec,
    .mce-btn-sec-lg { display: inline-block; margin: 0 2px; min-width: 120px; padding: 5px 10px; line-height: 25px; text-align: center; border-radius: 5px; font-weight: bold; text-decoration: none; background-color: #007bff; color: #ffffff; }

    .mce-btn:hover,
    .mce-btn-lg:hover, 
    .mce-btn-sec:hover,
    .mce-btn-sec-lg:hover { outline: 3px solid rgba(100,100,100,.2); }
    
    .mce-btn-lg, 
    .mce-btn-sec-lg { min-width: 200px; padding: 10px 15px; }

    .mce-btn-sec,
    .mce-btn-sec-lg { background-color: #6c757d; color: #ffffff; }
        
    .mce-fullwidth,
    .mce-fullwidth-column,
    .mce-fullwidth-center { position: relative; width: 100%; background-color: #f0f0f0; margin: 30px 0 30px 0; box-sizing: border-box; }

    .mce-fullwidth-column { display: flex; flex-wrap: wrap; }
        .mce-fullwidth-column .fullwidth-column { flex: 1; flex-grow: 1; min-width: 400px; box-sizing: border-box; }

    .mce-fullwidth-center .fullwidth-center { display: block; clear: both; width: 94%; max-width: 1400px; margin: 0 auto 0 auto; }
`;

// Vizuálne pomôcky k štýlom, ktoré sú použité len v editore
export const TinyMCEStyleClientVisual: string =
    `
    /* Vizuálne pomôcky pre tabuľku (tlačidlo: zobraziť / vizuálne pomôcky) */
    .table-visual-border,
    .table-visual-border td {   outline: 1px dashed rgba(136,136,136,0.3); }

    /* Vizuálne pomôcky pre bloky (tlačidlo: zobraziť / zobraziť bloky) */
    .mce-visualblocks .mce-container { border-width: 2px!important; }
    .mce-visualblocks .mce-container > .column { border-width: 3px!important; }
`;
