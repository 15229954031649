import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { UserRole, UserRoleList } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Radio, Link, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import SecurityIcon from '@mui/icons-material/Security';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): UserRole => ({
    id: 0,
    name: '',
    note: '',
    items: []
});

// Vstupné parametre
export interface UserRoleCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const UserRoleCreate = (props: UserRoleCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<UserRole>(EmptySource());
    const [sourceList, setSourceList] = useState<UserRoleList[]>([]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'userrole/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as UserRole);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam užívateľských rolí (načítam iba jeden krát po pripojení)
    const loadUserRoleRoles = () => {
        axios
            .get(AppConfig.ApiUri + 'userrolelist')
            .then(response => {
                setSourceList(response.data);
            });
    };
    useEffect(() => loadUserRoleRoles(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'userrole', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Užívateľská rola' : 'Nová užívateľská rola')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 70 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Názov užívateľskej roli, ktorý najlepšie popisuje jej význam.">
                                                    <SecurityIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="warning">
                                    <strong>Upozornenie!</strong> Užívateľské role sú momentálne v príprave. V aktuálnej verzii užívatelia nemajú žiadne obmedzenia, všetci majú rovnaké oprávnenia.
                                </Alert>
                            </Grid>

                            <Grid item xs={12}>
                                <TableContainer sx={{ height: 340, margin: '20px 0 10px 0' }}>
                                    <Table size="small" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '60%' }}>Modul</TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Užívateľ nemá prístup do vybraných modulov.">
                                                        <Link component="button" type="button" onClick={() => {
                                                            setSource(prev => ({ ...prev, items: [] }));
                                                        }}>
                                                            Zakázať
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Užívateľ má prístup do vybraných modulov, ale nemôže v nich vykonať úpravy.">
                                                        <Link component="button" type="button" onClick={() => {
                                                            setSource(prev => ({ ...prev, items: sourceList.map(item => ({ userRoleId: source.id, userRoleListId: item.id, editable: false })) }));
                                                        }}>
                                                            Náhľad
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Užívateľ má prístup do vybraných modulov a môže v nich vykonať úpravy.">
                                                        <Link component="button" type="button" onClick={() => {
                                                            setSource(prev => ({ ...prev, items: sourceList.map(item => ({ userRoleId: source.id, userRoleListId: item.id, editable: true })) }));
                                                        }}>
                                                            Zápis
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sourceList.map(row =>
                                                <TableRow key={row.id} >
                                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell align="center">
                                                        <Radio size="small"
                                                            checked={((source.items?.filter(item => item.userRoleListId === row.id)?.length ?? 0) === 0)}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setSource(prev => ({ ...prev, items: prev.items?.filter(item => item.userRoleListId !== row.id) }));
                                                                }
                                                            }} />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Radio size="small"
                                                            checked={((source.items?.filter(item => item.userRoleListId === row.id && item?.editable === false)?.length ?? 0) > 0)}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setSource(prev => ({ ...prev, items: [...prev.items?.filter(item => item.userRoleListId !== row.id) ?? [], { userRoleId: source.id, userRoleListId: row.id, editable: false }] }));
                                                                }
                                                            }} />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Radio size="small"
                                                            checked={((source.items?.filter(item => item.userRoleListId === row.id && item?.editable === true)?.length ?? 0) > 0)}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    setSource(prev => ({ ...prev, items: [...prev.items?.filter(item => item.userRoleListId !== row.id) ?? [], { userRoleId: source.id, userRoleListId: row.id, editable: true }] }));
                                                                }
                                                            }} />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 255 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default UserRoleCreate;