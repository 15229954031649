import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../../utility/Number';

// Modely
import { Branch, Department, Modules, Warehouse, WarehouseGroup } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, IconButton, FormControl, InputLabel, MenuItem, Select, ButtonGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CustomFieldValues from '../custom_field/CustomFieldValues';
import WarehouseCreateGroup, { WarehouseCreateGroupProps } from './WarehouseCreateGroup';
import Confirm, { ConfirmProps } from '../../../components/Confirm';

// Ikony
import ApartmentIcon from '@mui/icons-material/Apartment';
import EditIcon from '@mui/icons-material/Edit';
import SortIcon from '@mui/icons-material/Sort';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Warehouse => ({
    id: 0,
    warehouseGroupId: 0,
    branchId: 0,
    departmentId: 0,
    ordinalNumber: 0,
    name: '',
    address: '',
    note: ''
});

// Vstupné parametre
export interface WarehouseCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const WarehouseCreate = (props: WarehouseCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Warehouse>(EmptySource());
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [branches, setBranches] = useState<Branch[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [warehouseGroups, setWarehouseGroups] = useState<WarehouseGroup[]>([]);
    const [warehouseCreateGroup, setWarehouseCreateGroup] = useState<WarehouseCreateGroupProps>({
        open: false,
        keepMounted: false,
        onSave: () => loadWarehouseGroups(),
        onClose: () => setWarehouseCreateGroup(prev => ({ ...prev, open: false }))
    });

    // Načítam číselníky
    const loadBranches = () => {
        axios
            .get(AppConfig.ApiUri + 'branch')
            .then(response => setBranches((response.data as Branch[]).filter(item => item.actived)));
    };
    const loadDepartments = () => {
        axios
            .get(AppConfig.ApiUri + 'department')
            .then(response => setDepartments(response.data as Department[]));
    };
    const loadWarehouseGroups = () => {
        axios
            .get(AppConfig.ApiUri + 'warehousegroup')
            .then(response => setWarehouseGroups(response.data as WarehouseGroup[]));
    };
    useEffect(() => {
        loadBranches();
        loadDepartments();
        loadWarehouseGroups();
    }, []);

    // Pridať upraviť skupinu
    const handleGroupCreate = (id: number, copy?: boolean, field?: string) => {
        setWarehouseCreateGroup(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Vymazať skupinu
    const handleGroupDelete = (id: number) => {
        let name = warehouseGroups.find(item => item.id === id)?.name ?? '';
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                handleChange('warehouseGroupId', 0);
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'warehousegroup/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setWarehouseGroups(prev => [...prev.filter(item => item.id !== id)]);
                        }
                    })
                    .finally(() => { setLoading(false); });
            }
        }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'warehouse/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Warehouse);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.branchId === 0) {
            setWarning({ ...EmptyWarning, name: 'branchId', text: 'Musíte vybrať prevádzku!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'warehouse', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Sklad' : 'Nový sklad')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="warehouse-create-groupid">Skupina</InputLabel>
                                    <Select required labelId="warehouse-create-groupid" label="Skupina"
                                        IconComponent={() => null} value={source.warehouseGroupId ?? 0} name="warehouseGroupId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'warehouseGroupId' && setFocus(input)}
                                        endAdornment={
                                            <ButtonGroup variant="outlined">
                                                <IconButton size='small' onClick={() => handleGroupCreate(0)}><AddIcon /></IconButton>
                                                <IconButton size='small' onClick={() => handleGroupCreate(source.warehouseGroupId ?? 0)} disabled={(source.warehouseGroupId ?? 0) === 0}><EditIcon /></IconButton>
                                                <IconButton size='small' onClick={() => handleGroupDelete(source.warehouseGroupId ?? 0)} disabled={(source.warehouseGroupId ?? 0) === 0}><DeleteIcon /></IconButton>
                                            </ButtonGroup>}>
                                        <MenuItem key={0} value={0}>Žiadna</MenuItem>
                                        {warehouseGroups?.map(item => <MenuItem key={item.id} value={item.id}>{item.name ?? ''}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth required>
                                    <InputLabel id="warehouse-create-stocktype">Typ skladu</InputLabel>
                                    <Select labelId="warehouse-create-stocktype" label="Typ skladu" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        <MenuItem value={0}>Vlastný sklad</MenuItem>
                                        <MenuItem value={1}>Vlastný sklad bez pohybov</MenuItem>
                                        <MenuItem value={2}>Externý sklad bez pohybov</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <ApartmentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth required>
                                    <InputLabel id="warehouse-create-branch">Prevádzka (pobočka)</InputLabel>
                                    <Select labelId="warehouse-create-branch" label="Prevádzka (pobočka)" value={source.branchId ?? 0} name="branchId" onChange={(e) => {
                                        handleChange('departmentId', 0);
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                        inputRef={input => { (props.autoFocus === 'branchId' || props.autoFocus === 'branchName') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {branches?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl disabled={(source.branchId ?? 0) === 0} margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="warehouse-create-department">Stredisko (oddelenie)</InputLabel>
                                    <Select labelId="warehouse-create-department" label="Stredisko (oddelenie)" value={source.departmentId ?? 0} name="departmentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'departmentId' || props.autoFocus === 'departmentName') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {departments?.filter(item => item.branchId === source.branchId)?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="address" label="Adresa" fullWidth variant="outlined" autoComplete="off" value={source.address ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'address' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Celá adresa skladu">
                                                    <EditIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <CustomFieldValues moduleId={Modules.Warehouse} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <WarehouseCreateGroup {...warehouseCreateGroup} />
        </>
    )
}

export default WarehouseCreate;