import { useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Category, Domain, ExportFilter, StockAvailability, Tag } from '../../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../../utility/Number';

// Komponenty
import { Select, TextField, MenuItem, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CategorySelect from '../../category/CategorySelect';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import EuroIcon from '@mui/icons-material/Euro';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { NullMinDate } from '../../../utility/Date';

export interface ExportCreateFilterProductsProps {
    filter: ExportFilter;
    onChange: (filter: ExportFilter) => void;
}

const ExportCreateFilterProducts = (props: ExportCreateFilterProductsProps) => {

    // Stav
    const [domains, setDomains] = useState<Domain[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [stockAvailabilities, setStockAvailabilities] = useState<StockAvailability[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        props.onChange(({ ...props.filter, [property]: value }))
    }

    // Funkcia pre načítanie kategórii
    const loadDataCategories = () => {
        axios
            .get(AppConfig.ApiUri + 'category')
            .then(response => {
                setCategories(response.data as Category[]);
            });
    };

    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            });
    };

    // Načítam zoznam štítkov
    const loadTags = () => {
        axios
            .get(AppConfig.ApiUri + 'tag')
            .then(response => {
                setTags(response.data.filter((tag: Tag) => tag.actived));
            });
    };

    // Načítam zoznam stavov skladu
    const loadStockAvailabilities = () => {
        axios
            .get(AppConfig.ApiUri + 'stockavailability')
            .then(response => {
                setStockAvailabilities(response.data);
            });
    };

    useEffect(() => {
        loadDataCategories();
        loadDomains();
        loadTags();
        loadStockAvailabilities();
    }, []);

    return (
        <Grid container columnSpacing={1}>

            <Grid item xs={12}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <CategorySelect label='Kategória' activeCategoryId={props.filter.categoryId} categories={categories} onSelect={(id: number) => handleChange('categoryId', id)} onCategoriesChanged={loadDataCategories} />
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="filter-actived">Aktívne</InputLabel>
                    <Select labelId="filter-actived" label="Aktívne" value={props.filter.actived ?? 0} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        <MenuItem value={0}>Všetko</MenuItem>
                        <MenuItem value={1}>Áno</MenuItem>
                        <MenuItem value={2}>Nie</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="filter-domain">Doména</InputLabel>
                    <Select labelId="filter-domain" label="Doména" value={props.filter.domainId ?? 0} name="domainId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        <MenuItem value={0}>Všetko</MenuItem>
                        <MenuItem value={-1}>Nezaradené</MenuItem>
                        {domains?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="filter-stockavailability">Dostupnosť na sklade</InputLabel>
                    <Select labelId="filter-stockavailability" label="Dostupnosť na sklade" value={props.filter.stockAvailabilityId ?? 0} name="stockAvailabilityId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                        {stockAvailabilities?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel id="filter-tag">Štítok</InputLabel>
                    <Select labelId="filter-tag" label="Štítok" value={props.filter.tagId ?? 0} name="tagId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}>
                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                        {tags?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                    <FormControl margin="dense" fullWidth>
                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Vytvorené od" value={NullMinDate(props.filter.createdDateFrom)} onChange={(d) => { handleChange('createdDateFrom', d, 'date') }} />
                    </FormControl>
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                    <FormControl margin="dense" fullWidth>
                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Vytvorené do" value={NullMinDate(props.filter.createdDateTo)} onChange={(d) => { handleChange('createdDateTo', d, 'date') }} />
                    </FormControl>
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                    <FormControl margin="dense" fullWidth>
                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Upravené od" value={NullMinDate(props.filter.updatedDateFrom)} onChange={(d) => { handleChange('updatedDateFrom', d, 'date') }} />
                    </FormControl>
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                    <FormControl margin="dense" fullWidth>
                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Upravené do" value={NullMinDate(props.filter.updatedDateTo)} onChange={(d) => { handleChange('updatedDateTo', d, 'date') }} />
                    </FormControl>
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

            <Grid item xs={12} sm={6}>
                <TextField fullWidth margin="dense" name="priceFrom" type="text" label="Cena od" variant="outlined" autoComplete="off" value={props.filter.priceFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EuroIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField fullWidth margin="dense" name="priceTo" type="text" label="Cena do" variant="outlined" autoComplete="off" value={props.filter.priceTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <EuroIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField fullWidth margin="dense" name="quantityFrom" type="text" label="Zásoby od" variant="outlined" autoComplete="off" value={props.filter.quantityFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CorporateFareIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField fullWidth margin="dense" name="quantityTo" type="text" label="Zásoby do" variant="outlined" autoComplete="off" value={props.filter.quantityTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CorporateFareIcon />
                            </InputAdornment>
                        ),
                    }} />
            </Grid>

        </Grid>
    )
}

export default ExportCreateFilterProducts;