import React, { useState } from 'react';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';

// Komponenty
import { TextField, Typography, Button, Box, Tab, Tabs, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Iframe from '../../components/Iframe';
import TinyMCEDialog, { TinyMCEDialogProps } from '../tinymce/TinyMCEDialog';
import { TinyMCEStyleClient } from '../tinymce/TinyMCEStyleClient';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import { TabContext, TabPanel } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre argumenty TinyMCE
interface TinyMCEArgument {
    languageId: number;
    property: string;
}

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateDescriptionProps extends ProductCreateComponentProps {

};

const ProductCreateDescription = (props: ProductCreateDescriptionProps) => {

    // Stav
    const [tabDescriptionValue, setTabDescriptionValue] = useState<string>('0');
    const [tinyMCEDialog, setTinyMCEDialog] = useState<TinyMCEDialogProps>({
        open: false,
        content: '',
        contentStyle: 'body { font-family: Arial, sans-serif; }', // CSS štýl, ktorý sa pridáva do vygenerovaného HTML ako predvolený štýl
        // contentTagsIds: [],
        onSave: (content: string, argument: TinyMCEArgument) => {
            props.onChangeTranslation(argument.languageId, argument.property, content);
            handleTinymceClose();
        },
        onClose: () => handleTinymceClose()
    });

    // Zobrazenie HTML editoru
    const handleTinymceOpen = (argument: TinyMCEArgument, title: string, content: string) => {
        setTinyMCEDialog(prev => ({ ...prev, open: true, title: title, argument: argument, content: content }));
    };
    const handleTinymceClose = () => {
        setTinyMCEDialog(prev => ({ ...prev, open: false }));
    };

    return (
        <>
            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('descfull')} onChange={() => { props.onToggleAccordion('descfull') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Úplný popis</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabDescriptionValue} onChange={(e, v) => setTabDescriptionValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                            {props.languages.map((language, index) => <Tab key={language.id} value={index.toString()} label={language.code} />)}
                        </Tabs>
                    </Box>
                    <TabContext value={tabDescriptionValue}>
                        {props.languages.map((language, index) => {
                            return (
                                <TabPanel value={index.toString()} sx={{ px: '0', pb: 0, pt: 1 }} key={language.id}>
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={12} sx={{ height: '400px' }}>
                                            <Iframe title={'Úplný popis (' + language.code + ')'} content={(props.source.translations?.find(item => item.languageId === language.id)?.descriptionLong ?? '') + '<style>' + (tinyMCEDialog.contentStyle ?? '') + TinyMCEStyleClient + '</style>'} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }}
                                                onClick={() => {
                                                    handleTinymceOpen({
                                                        languageId: language.id ?? 0,
                                                        property: 'descriptionLong'
                                                    },
                                                        'Popis produktu (' + language.code + ')',
                                                        props.source.translations?.find(item => item.languageId === language.id)?.descriptionLong ?? '');
                                                }}>Upraviť</Button>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            )
                        })}
                    </TabContext>
                </AccordionDetails>
            </Accordion>

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('descshort')} onChange={() => { props.onToggleAccordion('descshort') }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Stručný popis</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabDescriptionValue} onChange={(e, v) => setTabDescriptionValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                            {props.languages.map((language, index) => <Tab key={language.id} value={index.toString()} label={language.code} />)}
                        </Tabs>
                    </Box>
                    <TabContext value={tabDescriptionValue}>
                        {props.languages.map((language, index) => {
                            return (
                                <TabPanel value={index.toString()} sx={{ px: '0', pb: 0, pt: 1 }} key={language.id}>
                                    <Grid container columnSpacing={1}>
                                        <Grid item xs={12}>
                                            <TextField fullWidth multiline rows={4} margin="dense" name="descriptionShort" label={'Stručný popis (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                value={props.source.translations?.find(item => item.languageId === language.id)?.descriptionShort ?? ''}
                                                onChange={(e) => { props.onChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputRef={input => index === 0 && props.autoFocus === 'descriptionShort' && props.setFocus(input)}
                                                inputProps={{ maxLength: 500 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <EditIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            )
                        })}
                    </TabContext>
                </AccordionDetails>
            </Accordion>



            <TinyMCEDialog {...tinyMCEDialog} />
        </>
    )
};

export default ProductCreateDescription;
