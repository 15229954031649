
export const TinyMCETemplates = [
    {
        title: 'Blok cez celú šírku obrazovky',
        description: '',
        content: `
            <div class="mce-fullwidth">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam congue ex quis elit congue accumsan. Vivamus eu ante nisl. Donec fermentum et sapien sed dignissim...</p>
            </div>
        `
    },
    {
        title: 'Blok cez celú šírku obrazovky - dva stĺpce',
        description: '',
        content: `
            <div class="mce-fullwidth-column">
                <div class="fullwidth-column">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam congue ex quis elit congue accumsan. Vivamus eu ante nisl. Donec fermentum et sapien sed dignissim...</p>
                </div>
                <div class="fullwidth-column">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam congue ex quis elit congue accumsan. Vivamus eu ante nisl. Donec fermentum et sapien sed dignissim...</p>
                </div>
            </div>
        `
    },
    {
        title: 'Blok cez celú šírku obrazovky - zarovnaný na stred',
        description: '',
        content: `
            <div class="mce-fullwidth-center">
                <div class="fullwidth-center">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam congue ex quis elit congue accumsan. Vivamus eu ante nisl. Donec fermentum et sapien sed dignissim...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (200px)',
        description: '',
        content: `
            <div class="mce-container w200">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (400px)',
        description: '',
        content: `
            <div class="mce-container w400">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (pevný)',
        description: '',
        content: `
            <div class="mce-container">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '3x stĺpec (200px)',
        description: '',
        content: `
            <div class="mce-container w200">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 3</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '3x stĺpec (400px)',
        description: '',
        content: `
            <div class="mce-container w400">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 3</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '3x stĺpec (pevný)',
        description: '',
        content: `
            <div class="mce-container">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (2:1) (200px)',
        description: '',
        content: `
            <div class="mce-container w200 third">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (2:1) (400px)',
        description: '',
        content: `
            <div class="mce-container w400 third">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (2:1) (pevný)',
        description: '',
        content: `
            <div class="mce-container third">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (1:2) (200px)',
        description: '',
        content: `
            <div class="mce-container w200 third-end">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (1:2) (400px)',
        description: '',
        content: `
            <div class="mce-container w400 third-end">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
    {
        title: '2x stĺpec (1:2) (pevný)',
        description: '',
        content: `
            <div class="mce-container third-end">
                <div class="column">
                    <h2>Column 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
                <div class="column">
                    <h2>Column 2</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin tincidunt nisl, id iaculis nulla lobortis...</p>
                </div>
            </div>
        `
    },
];