import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Category, CategoryTranslation, Domain, Language, ProductSortingTypes, File, ExternalCatalog, CategoryExternalCatalog, Modules } from '../../models/Models';

// Utility
import { ConvertToInt, ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';
import { EscapeSlug } from '../../utility/Text';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Tooltip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Tab, Tabs, Card, CardActions, CardMedia, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FilesDialog, { FilesDialogProps } from '../file/FilesDialog';
import CategorySelect from './CategorySelect';
import CustomFieldValues from '../settings/custom_field/CustomFieldValues';
import { TabContext, TabPanel } from '@mui/lab';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SortIcon from '@mui/icons-material/Sort';
import LinkIcon from '@mui/icons-material/Link';
import EuroIcon from '@mui/icons-material/Euro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Category => ({
    id: 0,
    parentId: 0,
    actived: true,
    ordinalNumber: 0,
    fileId: 0,
    sorting: 0,
    domains: [],
    translations: [],
    externalCatalogs: [],
    other: {
        fileSrcMedium: ''
    }
});

// Vstupné parametre
export interface CategoryCreateProps {
    open: boolean;
    id?: number;
    parentId?: number;
    autoFocus?: string;
    keepMounted?: boolean;
    categories: Category[];             // Zoznam kategórii
    onCategoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSave?: () => void;
    onClose: () => void;
}

const CategoryCreate = (props: CategoryCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [externalCatalogs, setExternalCatalogs] = useState<ExternalCatalog[]>([]);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const [source, setSource] = useState<Category>({ ...EmptySource(), parentId: props.parentId ?? 0 });
    const [tabSeoValue, setTabSeoValue] = useState<string>('0');

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Zobrazenie súborov
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        watermark: true,
        onSelect: (files: File[]) => {
            if (files.length > 0 && files[0].fileIsImage) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource({ ...EmptySource(), parentId: props.parentId ?? 0 });
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'category/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as Category);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.open, props.parentId]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zoznam externých katalógov
    const loadExternalCatalogs = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'externalcatalog')
            .then(response => {
                setExternalCatalogs(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadExternalCatalogs(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: CategoryTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            if (property === 'name') {
                item['url'] = EscapeSlug(value);
                item['seoHeadline'] = value;
                item['seoTitle'] = value;
            }
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }
    const handleChangeExternalCatalog = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: CategoryExternalCatalog = { ...prev?.externalCatalogs?.find(item => item.externalCatalogId === id), externalCatalogId: id, [property]: value };
            const next = { ...prev, externalCatalogs: [...prev?.externalCatalogs?.filter(item => item.externalCatalogId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'category', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <FilesDialog {...filesDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Kategória' : 'Nová kategória')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <CategorySelect label='Nadradená kategória' activeCategoryId={source.parentId} hiddenCategoryId={props.id} categories={props.categories} onSelect={(id: number) => handleChange('parentId', id)} onCategoriesChanged={props.onCategoriesChanged} />
                                </FormControl>
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <React.Fragment key={language.id}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField required margin="dense" name='name' label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && index === 0 && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Názov kategórie sa zobrazí ako text tlačidla v strome kategórii.">
                                                                <EditIcon className="formIconHelp" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField required margin="dense" name="url" label={'URL (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && props.autoFocus === 'url' && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.url ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, EscapeSlug(e.target.value)) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="URL - unikátna adresa, ktorá bude zastupovať túto kategóriu. Napr. url 'modne-doplnky' pre kategóriu s názvom 'Módne doplnky'.">
                                                                <LinkIcon className="formIconHelp" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => { handleToggleAccordion('settings') }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nastavenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={6}>
                                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                                    <SortIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="payment-create-feetype">Zoradenie produktov</InputLabel>
                                                    <Select required labelId="payment-create-feetype" label="Zoradenie produktov" value={source.sorting ?? 0} name="sorting" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'sorting' && setFocus(input)}>
                                                        {ProductSortingTypes.map(type => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="category-create-domain">Domény</InputLabel>
                                                    <Select multiple labelId="category-create-domain" label="Domény" name="domains" value={source.domains ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'domains' && setFocus(input)}>
                                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} my={1}>
                                                <Alert severity="warning">
                                                    Zoradenie produktov a obmedzenie pre vybrané domény sú platné iba v rámci objednávkového systému pre zákazníka (eshop a podobne).
                                                    Pri obmedzení domény platí pravidlo, že ak je nie je označená žiadna možnosť, tak je kategória zobrazená na každej doméne.
                                                    Ak je v nastaveniach cieľovej domény aktívne prísne zobrazenie, tak daná doména zobrazí len tie kategórie, ktoré majú označenú danú doménu.
                                                </Alert>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid item xs={12} md={4}>
                                                    {(source.fileId ?? 0) > 0 &&
                                                        <Card sx={{ mt: 1 }}>
                                                            <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                                            <CardActions>
                                                                <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                                            </CardActions>
                                                        </Card>
                                                    }
                                                    <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('catalogs')} onChange={() => handleToggleAccordion('catalogs')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Katalógy</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {externalCatalogs.map((externalCatalog, index) => {
                                                return (
                                                    <React.Fragment key={externalCatalog.id}>
                                                        <Grid item xs={12} sm={8}>
                                                            <TextField margin="dense" name='category' label={'Kategória (' + (externalCatalog.name ?? '') + ')'} fullWidth variant="outlined" autoComplete="off"
                                                                value={source.externalCatalogs?.find(item => item.externalCatalogId === externalCatalog.id)?.category ?? ''}
                                                                onChange={(e) => { handleChangeExternalCatalog((externalCatalog?.id ?? 0), e.target.name, e.target.value) }}
                                                                inputProps={{ maxLength: 255 }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Tooltip title="Cieľová kategória podľa číselníka cieľového katalógu.">
                                                                                <EditIcon className="formIconHelp" />
                                                                            </Tooltip>
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </Grid>
                                                        <Grid item xs={12} sm>
                                                            <TextField margin="dense" name="cpc" type="text" label={'CPC (' + (externalCatalog.name ?? '') + ')'} fullWidth variant="outlined" autoComplete="off"
                                                                value={source.externalCatalogs?.find(item => item.externalCatalogId === externalCatalog.id)?.cpc ?? ''}
                                                                onChange={(e) => { handleChangeExternalCatalog((externalCatalog?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Tooltip title="CPC (Cost Per Click) - maximálny poplatok za preklik.">
                                                                                <EuroIcon className="formIconHelp" />
                                                                            </Tooltip>
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('description')} onChange={() => handleToggleAccordion('description')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Popis</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {languages.map((language, index) => {
                                                return (
                                                    <Grid key={language.id} item xs={12}>
                                                        <TextField fullWidth multiline rows={3} margin="dense" name="description" label={'Popis kategórie (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                            value={source.translations?.find(item => item.languageId === language.id)?.description ?? ''}
                                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                            inputRef={input => index === 0 && props.autoFocus === 'description' && setFocus(input)}
                                                            inputProps={{ maxLength: 500 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Popis kategórie sa zobrazuje nad zoznamom produktov. Pomocou značiek je do popisu možné vložiť napr. textový článok alebo galériu.">
                                                                            <EditIcon className="formIconHelp" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('XXXXX1')} onChange={() => handleToggleAccordion('XXXXX1')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>SEO</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={tabSeoValue} onChange={(e, v) => setTabSeoValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                                {languages.map((language, index) => <Tab key={language.id} value={index.toString()} label={language.code} />)}
                                            </Tabs>
                                        </Box>
                                        <TabContext value={tabSeoValue}>
                                            {languages.map((language, index) => {
                                                return (
                                                    <TabPanel value={index.toString()} sx={{ p: 0, pt: 1 }} key={language.id}>
                                                        <Grid container columnSpacing={1}>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField margin="dense" name='seoHeadline' label={'Nadpis stránky (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                                    inputRef={input => (props.autoFocus === 'seoHeadline') && index === 0 && setFocus(input)}
                                                                    value={source.translations?.find(item => item.languageId === language.id)?.seoHeadline ?? ''}
                                                                    onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <EditIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField margin="dense" name='seoTitle' label={'Titulok stránky (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                                    inputRef={input => (props.autoFocus === 'seoTitle') && index === 0 && setFocus(input)}
                                                                    value={source.translations?.find(item => item.languageId === language.id)?.seoTitle ?? ''}
                                                                    onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <EditIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField fullWidth multiline rows={3} margin="dense" name="seoDescription" label={'Popis stránky (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                                    value={source.translations?.find(item => item.languageId === language.id)?.seoDescription ?? ''}
                                                                    onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                                    inputRef={input => index === 0 && props.autoFocus === 'seoDescription' && setFocus(input)}
                                                                    inputProps={{ maxLength: 255 }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <EditIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            </Grid>
                                                        </Grid>
                                                    </TabPanel>
                                                )
                                            })}
                                        </TabContext>
                                    </AccordionDetails>
                                </Accordion>

                                <CustomFieldValues moduleId={Modules.Category} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                            </Grid>

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id, parentId: prev.parentId }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default CategoryCreate;