import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../../utility/Number';

// Modely
import { Agreement, AgreementTranslation, Language } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Iframe from '../../../components/Iframe';
import ContentTagsDialog, { ContentTagsDialogProps } from '../content_tag/ContentTagsDialog';
import TinyMCEDialog, { TinyMCEDialogProps } from '../../tinymce/TinyMCEDialog';
import { TinyMCEStyleClient } from '../../tinymce/TinyMCEStyleClient';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { TabContext, TabPanel } from '@mui/lab';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Agreement => ({
    id: 0,
    actived: true,
    type: 0,
    validity: 12,
    translations: []
});

// Predpis pre argumenty TinyMCE
interface TinyMCEArgument {
    languageId: number;
    property: string;
}

// Vstupné parametre
export interface AgreementCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const AgreementCreate = (props: AgreementCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Agreement>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [tabLanguageValue, setTabLanguageValue] = useState<string>('0');
    const [contentTagsDialog, setContentTagsDialog] = useState<ContentTagsDialogProps>({
        ids: [],
        open: false,
        onClose: () => handleContentTagsClose()
    });
    const [tinyMCEDialog, setTinyMCEDialog] = useState<TinyMCEDialogProps>({
        open: false,
        content: '',
        contentTagsIds: [],
        onSave: (content: string, argument: TinyMCEArgument) => {
            handleChangeTranslation(argument.languageId, argument.property, content);
            handleTinymceClose();
        },
        onClose: () => handleTinymceClose()
    });

    // Zobrazenie okna značiek
    const handleContentTagsOpen = () => {
        setContentTagsDialog(prev => ({ ...prev, open: true }));
    };
    const handleContentTagsClose = () => {
        setContentTagsDialog(prev => ({ ...prev, open: false }));
    };

    // Zobrazenie HTML editoru
    const handleTinymceOpen = (argument: TinyMCEArgument, title: string, content: string) => {
        setTinyMCEDialog(prev => ({ ...prev, open: true, title: title, argument: argument, content: content }));
    };
    const handleTinymceClose = () => {
        setTinyMCEDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource(EmptySource());
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'agreement/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Agreement);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: AgreementTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'agreement', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Súhlas zákazníka' : 'Nový súhlas zákazníka')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived ?? false} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="agreement-create-type">Typ súhlasu *</InputLabel>
                                    <Select required labelId="agreement-create-type" label="Typ súhlasu *" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        <MenuItem value={0}>Zaškrtávacie políčko</MenuItem>
                                        <MenuItem value={1}>Automatický súhlas (oznámenie o súhlase)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="validity" type="number" label="Platnosť súhlasu (mesiace)" variant="outlined" value={source.validity ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'validity' && setFocus(input)}
                                    inputProps={{ min: 1, max: 1200, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Obdobie (v mesiacoch) počas ktorého je súhlas platný.">
                                                    <DateRangeIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <Grid item xs={12} key={language.id}>
                                        <TextField required margin="dense" name="label" label={'Popisok k súhlasu (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                            inputRef={input => (props.autoFocus === 'label' || props.autoFocus === undefined) && index === 0 && setFocus(input)}
                                            value={source.translations?.find(item => item.languageId === language.id)?.label ?? ''}
                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 255 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip title="Popisok súhlasu, ktorý zobrazí sa veďľa zaškrtávacieho políčka alebo v mieste upozornenia.">
                                                            <EditIcon className="formIconHelp" />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabLanguageValue} onChange={(e, v) => setTabLanguageValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        {languages.map((language, index) => <Tab key={language.id} value={index.toString()} label={'Náhľad (' + language.code + ')'} />)}
                                    </Tabs>
                                </Box>
                                <TabContext value={tabLanguageValue}>
                                    {languages.map((language, index) => {
                                        return (
                                            <TabPanel value={index.toString()} sx={{ px: '0', pb: 0, pt: 1 }} key={language.id}>
                                                <Grid container columnSpacing={1}>
                                                    <Grid item xs={12} sx={{ height: '200px' }}>
                                                        <Iframe title={'Šablóna (' + language.code + ')'} content={(source.translations?.find(item => item.languageId === language.id)?.text ?? '') + '<style>' + (tinyMCEDialog.contentStyle ?? '') + TinyMCEStyleClient + '</style>'} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }}
                                                            onClick={() => {
                                                                handleTinymceOpen({
                                                                    languageId: language.id ?? 0,
                                                                    property: 'text'
                                                                },
                                                                    'Úprava informačného textu (' + language.code + ')',
                                                                    source.translations?.find(item => item.languageId === language.id)?.text ?? '');
                                                            }}>Upraviť</Button>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                        )
                                    })}
                                </TabContext>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info" action={<Button variant="contained" color="primary" size="small" onClick={handleContentTagsOpen}>Značky</Button>}>
                                    V obsahu a parametroch je možné použiť značky pre vloženie dynamického textu ako dátum, údaje z dokladu a podobne.
                                </Alert>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <ContentTagsDialog {...contentTagsDialog} />
            <TinyMCEDialog {...tinyMCEDialog} />
        </>
    )
}

export default AgreementCreate;