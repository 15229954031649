import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { ExternalDocumentsFilter, ExternalDocument } from '../../models/Models';

// Utility
import Debounce from '../../utility/Debounce';
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { FormatDecimal } from '../../utility/Format';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../utility/DataGrid';
import { FileOpen } from '../../utility/File';
import format from 'date-fns/format';
import { useQuery } from '../../utility/URL';

// Komponenty
import { Backdrop, Button, CircularProgress, Divider, Grid, IconButton, ListItemIcon, Menu } from '@mui/material';
import { DataGrid, GridColDef, GridDensity, GridFilterModel, GridRenderCellParams, GridRowId, GridSortItem, GridSortModel, GridValueFormatterParams, GridValueGetterParams, skSK } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import ExternalDocumentsFiltering, { ExternalDocumentsFilteringProps } from './ExternalDocumentsFiltering';
import ExternalDocumentCreate, { ExternalDocumentCreateProps } from './ExternalDocumentCreate';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useHistory } from 'react-router-dom';

// Rozšírenie pre vlastný index zoradenia
interface GridSortModelIndex extends GridSortItem {
    index: number;
}

// Vstupné parametre
export interface ExternalDocumentsProps {
    type?: number;
    typeName?: string;
    onSelectionChanged?: (rows: ExternalDocument[], confirm: boolean) => void;
}

// Komponent pre zoznam užívateľov
const ExternalDocuments = (props?: ExternalDocumentsProps) => {

    // Druh dokladu
    const externalDocumentTypeId = (props !== undefined ? props?.type ?? 0 : 0);
    const externalDocumentTypeName = (props !== undefined ? props?.typeName ?? '' : '');

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [externalDocumentsFiltering, setRowsFiltering] = useState<ExternalDocumentsFilteringProps>({
        open: false,
        keepMounted: true,
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setRowsFiltering(prev => ({ ...prev, open: false }))
    });

    // Nastavenia stĺpcov
    const gridSettings = new DataGridColumnSettings({ uid: 'externaldocuments-' + externalDocumentTypeId });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'done', headerName: 'Vybavené', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        { field: 'paid', headerName: 'Uhradené', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        { field: 'number', headerName: 'Číslo (externé)', hide: false, minWidth: 60, flex: 0.3 },
        {
            field: 'dateOfIssue', headerName: 'Dátum vystavenia', hide: HidePoint().sm, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfIssue']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfDue', headerName: 'Dátum splatnosti', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfDue']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfTaxLiability', headerName: 'Dátum zd. plnenia', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfTaxLiability']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfDelivery', headerName: 'Dátum dodania', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfDelivery']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'dateOfExpiration', headerName: 'Dátum platnosti', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['dateOfExpiration']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy') : '-')
        },
        {
            field: 'orderCustomer', headerName: 'Partner', hide: HidePoint().sm, minWidth: 60, flex: 0.8, sortable: false,
            valueGetter: (params: GridValueGetterParams) => (params.row.other.customerName ?? '')
        },
        { field: 'note', headerName: 'Poznámka', hide: false, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'symbolVariable', headerName: 'VS', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'symbolSpecific', headerName: 'ŠS', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'symbolConstant', headerName: 'KS', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        {
            field: 'price', headerName: 'Suma', hide: HidePoint().lg, minWidth: 80, flex: 0.3, align: 'right', headerAlign: 'right', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        { field: 'currencyCode', headerName: 'Mena', hide: false, minWidth: 60, flex: 0.2, sortable: false },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 160, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Vytlačiť" title="Vytlačiť" size="small" disabled={(params.row.other.fileSrc ?? '') === ''} onClick={() => handlePrint(params.value ?? 0, params.row.other.fileSrc ?? '')}>
                        <PrintIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Kopírovať" title="Kopírovať (ctrl + enter)" size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, (params.row.other.externalDocumentTypeName ?? 'Doklad') + ' č.: ' + (params.row.number ?? ''))}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Možnosti zoradenia (index podľa DB, názov stĺpca v gride a jeho smer)
    const columnsSortDefault: number = 1;
    const columnsSort: GridSortModelIndex[] = [
        { index: 0, field: 'id', sort: 'asc' },
        { index: 1, field: 'id', sort: 'desc' },
        { index: 0, field: 'createdDate', sort: 'asc' },
        { index: 1, field: 'createdDate', sort: 'desc' },
        { index: 2, field: 'number', sort: 'asc' },
        { index: 3, field: 'number', sort: 'desc' },
        { index: 4, field: 'dateOfIssue', sort: 'asc' },
        { index: 5, field: 'dateOfIssue', sort: 'desc' },
        { index: 6, field: 'dateOfTaxLiability', sort: 'asc' },
        { index: 7, field: 'dateOfTaxLiability', sort: 'desc' },
        { index: 8, field: 'dateOfDue', sort: 'asc' },
        { index: 9, field: 'dateOfDue', sort: 'desc' },
        { index: 10, field: 'updatedDate', sort: 'asc' },
        { index: 11, field: 'updatedDate', sort: 'desc' },
        { index: 12, field: 'price', sort: 'asc' },
        { index: 13, field: 'price', sort: 'desc' },
        { index: 14, field: 'dateOfDelivery', sort: 'asc' },
        { index: 15, field: 'dateOfDelivery', sort: 'desc' },
        { index: 16, field: 'dateOfExpiration', sort: 'asc' },
        { index: 17, field: 'dateOfExpiration', sort: 'desc' }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<ExternalDocument[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([]);
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<ExternalDocumentsFilter>({ page: 0, pageSize: gridSettings.pageSizeApply(25), sort: columnsSortDefault, externalDocumentTypeId: externalDocumentTypeId });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.number ?? '') !== '') { count++; }
        if (rowsFilter.dateOfIssueFrom !== undefined) { count++; }
        if (rowsFilter.dateOfIssueTo !== undefined) { count++; }
        if ((rowsFilter.priceFrom ?? 0) > 0) { count++; }
        if ((rowsFilter.priceTo ?? 0) > 0) { count++; }
        if (rowsFilter.dateOfDeliveryFrom !== undefined) { count++; }
        if (rowsFilter.dateOfDeliveryTo !== undefined) { count++; }
        if (rowsFilter.dateOfExpirationFrom !== undefined) { count++; }
        if (rowsFilter.dateOfExpirationTo !== undefined) { count++; }
        if ((rowsFilter.done ?? 0) > 0) { count++; }
        if ((rowsFilter.paid ?? 0) > 0) { count++; }
        if ((rowsFilter.phone ?? '') !== '') { count++; }
        if ((rowsFilter.email ?? '') !== '') { count++; }
        if ((rowsFilter.firstname ?? '') !== '') { count++; }
        if ((rowsFilter.lastname ?? '') !== '') { count++; }
        if ((rowsFilter.company ?? '') !== '') { count++; }
        if ((rowsFilter.regId ?? '') !== '') { count++; }
        if ((rowsFilter.taxId ?? '') !== '') { count++; }
        if ((rowsFilter.vatId ?? '') !== '') { count++; }
        if ((rowsFilter.city ?? '') !== '') { count++; }
        if ((rowsFilter.countryId ?? 0) > 0) { count++; }
        return count;
    };

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Automatické odovzdanie označených záznamov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            const ids = rowsSelected.map(r => r as number);
            props.onSelectionChanged(rows.filter(item => ids.includes((item.id ?? 0))) ?? [], false);
        }
    }, [rowsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pridať upraviť doklad
    const [externalDocumentCreate, setExternalDocumentCreate] = useState<ExternalDocumentCreateProps>({
        open: false,
        keepMounted: true,
        externalDocumentTypeId: externalDocumentTypeId,
        externalDocumentTypeName: externalDocumentTypeName,
        onSave: () => setRowsFilter(prev => ({ ...prev })),
        onClose: () => setExternalDocumentCreate(prev => ({ ...prev, open: false }))
    });

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        let sortIndex: number = columnsSortDefault;
        if (sortModel !== undefined && sortModel.length > 0) {
            sortIndex = columnsSort.find(s => s.field === sortModel[0].field && s.sort === sortModel[0].sort)?.index ?? 0;
        }
        if (rowsFilter.sort !== sortIndex) {
            setRowsFilter(prev => ({ ...prev, sort: sortIndex }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Dvoj-klik v zozname
    const handleDoubleClick = (id: number, field: string) => {
        // Ak požadujem výber záznamu, tak po dvoj-kliku prenesiem konkrétny záznam
        if (props?.onSelectionChanged) {
            const item = rows.find(item => item.id === id);
            if (item !== undefined) {
                props.onSelectionChanged([item], true);
                return;
            }
        }
        // Predvolená akcia pre úpravu záznamu
        handleCreate(id, false, field);
    };

    // Vygenerovať nový alebo stiahnuť nahraný doklad
    const handlePrint = (id: number, fileSrc: string) => {
        // Stiahnuť nahraný súbor        
        if (fileSrc.length > 0) {
            FileOpen(fileSrc, '_blank');
            return;
        }
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setExternalDocumentCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.EXTERNAL_DOCUMENTS + (props?.type ?? 0).toString() + '/');
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'externaldocument/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'externaldocument/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: gridSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [{
                field: 'orderNumber',
                getValue: (row: any) => {
                    return row.other?.orderNumber ?? '';
                }
            }, {
                field: 'orderCustomer',
                getValue: (row: any) => {
                    return (row?.other.orderCustomerPerson ?? false) === true ? (row?.other.orderCustomerFirstname ?? '') + ' ' + (row?.other.orderCustomerLastname ?? '') : (row?.other.orderCustomerCompany ?? '');
                }
            }, {
                field: 'externaldocumentTypeName',
                getValue: (row: any) => {
                    return row.other?.externalDocumentTypeName ?? '';
                }
            }, {
                field: 'companyName',
                getValue: (row: any) => {
                    return row.company?.name ?? '';
                }
            }]
        });
    };

    // Funkcia pre načítanie zákazníkov
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'externaldocument', {
                params: rowsFilter
            })
            .then(response => {
                setRows(response.data?.list ?? []);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Obsah */}
            <Content>
                <ContentTop>
                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nový doklad</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        externalDocumentTypeId: externalDocumentTypeId
                                    }));
                                }}
                                autoFocus={true}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setRowsFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rows}
                            rowCount={rowsCount}

                            pagination
                            paginationMode="server"
                            page={rowsFilter.page}
                            pageSize={rowsFilter.pageSize}

                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                            onPageSizeChange={(pageSize) => {
                                setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                gridSettings.pageSizeChanged(pageSize);
                            }}

                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}

                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleDoubleClick(e.row.id, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, (e.row.other.externalDocumentTypeName ?? 'Doklad') + ' č.: ' + (e.row.number ?? ''));
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentBottom>
            </Content>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre filtráciu */}
            <ExternalDocumentsFiltering {...externalDocumentsFiltering} />

            {/* Formulár pre nový záznam */}
            <ExternalDocumentCreate {...externalDocumentCreate} />

        </>
    )
}

export default ExternalDocuments;