
// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { PriceGroup, PriceList, ProductPriceList, Unit } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, MathRound2 } from '../../utility/Number';

// Komponenty
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import PercentIcon from '@mui/icons-material/Percent';
import DeleteIcon from '@mui/icons-material/Delete';
import FunctionsIcon from '@mui/icons-material/Functions';
import EuroIcon from '@mui/icons-material/Euro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductCreateSupplier from './ProductCreateSupplier';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreatePriceProps extends ProductCreateComponentProps {
    priceLists: PriceList[];
    priceGroups: PriceGroup[];
    vatRates: number[];
    units: Unit[];
};

const ProductCreatePrice = (props: ProductCreatePriceProps) => {

    // Zmeny vo formulári
    const handleChangePriceList = (id: number, property: string, value: any) => {
        props.setSource(prev => {
            const item: ProductPriceList = { ...prev?.priceLists?.find(item => item.priceListId === id), priceListId: id, [property]: value };
            const next = { ...prev, priceLists: [...prev?.priceLists?.filter(item => item.priceListId !== id) ?? [], item] };
            return next;
        });
    }

    // Pridať, upraviť alebo vymazať množstevnú zľavu
    const handleChangeVolumeDiscounts = (index: number, property: string, value: any) => {
        props.setSource(prev => {
            let changed = prev.volumeDiscounts ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...prev, volumeDiscounts: changed };
        });
    }
    const handleCreateVolumeDiscounts = () => {
        props.setSource(prev => ({ ...prev, volumeDiscounts: [...(prev.volumeDiscounts ?? []), { priceListId: 0, minimalQuantity: 0, discount: 0 }] }));
    }
    const handleDeleteVolumeDiscounts = (index: number) => {
        props.setSource(prev => {
            let changed = prev.volumeDiscounts ?? [];
            return { ...prev, volumeDiscounts: changed.filter(deleted => deleted !== changed[index]) ?? [] };
        });
    }

    // Funkcia pre aplikovanie zľavy, ak je zľava nulová tak vrátim pôvodnú cenu
    const applyDiscount = (price: number, discount: number): number => MathRound2(discount === 0 || price === 0 ? price : price * ((100 - discount) / 100));

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '30%' }}>Cena {(props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')}</TableCell>
                                    <TableCell sx={{ width: '30%' }}>Akciová cena {(props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <strong>Základná cena</strong>
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth required size="small" name="price" type="text" variant="outlined" autoComplete="off" value={props.source.price ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="priceDiscount" type="text" variant="outlined" autoComplete="off" value={props.source.priceDiscount ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />

                                    </TableCell>
                                </TableRow>

                                {props.priceLists?.map((pricelist, index) => {
                                    const priceListItem = props.source.priceLists?.find(item => item.priceListId === pricelist.id);
                                    return (
                                        <TableRow
                                            key={pricelist.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <strong>{pricelist.name}</strong><br />
                                                zľava {pricelist.discount}% alebo:
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="price" type="text" variant="outlined" autoComplete="off"
                                                    placeholder={'= ' + applyDiscount(props.source.price ?? 0, pricelist.discount ?? 0).toString()}
                                                    value={priceListItem?.price ?? ''}
                                                    onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="discount" type="text" variant="outlined" autoComplete="off"
                                                    placeholder={'= ' + applyDiscount(props.source.priceDiscount ?? 0, pricelist.discount ?? 0).toString()}
                                                    value={priceListItem?.discount ?? ''}
                                                    onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Odporúčaná cena
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <TextField fullWidth size="small" name="priceRecommended" type="text" variant="outlined" autoComplete="off" value={props.source.priceRecommended ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableBody>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Cenová skupina
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <Select fullWidth size="small" labelId="product-create-pricegroup" value={props.source.priceGroupId ?? 0} name="priceGroupId" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                            inputRef={input => props.autoFocus === 'priceGroupId' && props.setFocus(input)}>
                                            <MenuItem value={0}>Predvolená</MenuItem>
                                            {props.priceGroups.map(item => (<MenuItem key={item.id} value={item.id}>{item.name ?? ''}</MenuItem>))}
                                        </Select>
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}></TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Nákupná cena (bez DPH) / Výška DPH
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <TextField fullWidth size="small" name="pricePurchase" type="text" variant="outlined" autoComplete="off" value={props.source.pricePurchase ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <Select fullWidth labelId="product-create-purchasevatrate" size="small" value={props.source.pricePurchaseVatRate ?? 0} name="pricePurchaseVatRate" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                            inputRef={input => props.autoFocus === 'pricePurchaseVatRate' && props.setFocus(input)}>
                                            {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                            {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                        </Select>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Vážená nákupná cena (bez DPH)
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <TextField fullWidth size="small" name="priceStockWac" type="text" variant="outlined" autoComplete="off" value={props.source.priceStockWac ?? ''} disabled
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}></TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Priemerná skladová cena (bez DPH)
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <TextField fullWidth size="small" name="priceStockAverage" type="text" variant="outlined" autoComplete="off" value={props.source.priceStockAverage ?? ''} disabled
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}></TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>

            <Box sx={{ mt: 2 }}>
                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('suppliers')} onChange={() => { props.onToggleAccordion('suppliers') }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Dodávatelia ({props.source.suppliers?.length ?? 0})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductCreateSupplier {...props} />
                    </AccordionDetails>
                </Accordion>

                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('volumediscounts')} onChange={() => { props.onToggleAccordion('volumediscounts') }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Množstevné zľavy ({props.source.volumeDiscounts?.length ?? 0})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(props.source.volumeDiscounts?.length ?? 0) > 0 &&
                            <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <Table size="small" sx={{ minWidth: '500px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Cenník</TableCell>
                                                <TableCell>Od množstva</TableCell>
                                                <TableCell>Zľava %</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.source.volumeDiscounts?.map((discount, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell sx={{ width: '33%', p: 0.5, pl: 0 }}>
                                                        <Select fullWidth size="small" name="priceListId"
                                                            value={discount.priceListId ?? 0}
                                                            onChange={(e) => { handleChangeVolumeDiscounts(index, e.target.name, e.target.value) }}>
                                                            <MenuItem key={0} value={0}>Žiadny (základná cena)</MenuItem>
                                                            {props.priceLists?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField required fullWidth size="small" name="minimalQuantity" type="text" variant="outlined" autoComplete="off" value={discount.minimalQuantity ?? ''}
                                                            onChange={(e) => { handleChangeVolumeDiscounts(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Množstvo od ktorého je uplatnená zľava">
                                                                            <FunctionsIcon className="formIconHelp" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField required fullWidth size="small" name="discount" type="text" variant="outlined" autoComplete="off" value={discount.discount ?? ''}
                                                            onChange={(e) => { handleChangeVolumeDiscounts(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Zľava, ktorá je uplatnená po dosiahnutí zadaného množstva">
                                                                            <PercentIcon className="formIconHelp" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                    <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                        <IconButton aria-label="Vymazať" onClick={() => handleDeleteVolumeDiscounts(index)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        }
                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleCreateVolumeDiscounts()}>Pridať zľavu</Button>
                        <Alert severity="warning" sx={{ mt: 1 }}>
                            Ak je pri produkte nastavená akákoľvek množstevná zľava (bez ohľadu na vybraný cenník), tak pre daný produkt neplatia žiadne iné nastavenia množstevných zliav.
                            V tomto prípade je potrebné nastaviť všetky požadované množstevné zľavy pre všetky cenníky priamo pri produkte (nastavenia z cenníkov sú ignorované).
                        </Alert>
                    </AccordionDetails>
                </Accordion>

                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('cartquantity')} onChange={() => { props.onToggleAccordion('cartquantity') }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Limity ({(props.source.priceLists?.filter(item => (item.cartMinimalQuantity ?? 0) > 0 || (item.cartMaximalQuantity ?? 0) > 0)?.length ?? 0) + ((props.source.cartMinimalQuantity ?? 0) > 0 || (props.source.cartMaximalQuantity ?? 0) > 0 ? 1 : 0)})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Table size="small" sx={{ minWidth: '500px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell sx={{ width: '30%' }}>Min. obj. množstvo</TableCell>
                                            <TableCell sx={{ width: '30%' }}>Max. obj. množstvo</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                Základná cena
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="cartMinimalQuantity" type="text" variant="outlined" autoComplete="off" value={props.source.cartMinimalQuantity ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FunctionsIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="cartMaximalQuantity" type="text" variant="outlined" autoComplete="off" value={props.source.cartMaximalQuantity ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FunctionsIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                        </TableRow>
                                        {props.priceLists?.map((pricelist, index) => {
                                            const priceListItem = props.source.priceLists?.find(item => item.priceListId === pricelist.id);
                                            return (
                                                <TableRow
                                                    key={pricelist.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {pricelist.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField fullWidth size="small" name="cartMinimalQuantity" type="text" variant="outlined" autoComplete="off"
                                                            value={priceListItem?.cartMinimalQuantity ?? ''}
                                                            onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FunctionsIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField fullWidth size="small" name="cartMaximalQuantity" type="text" variant="outlined" autoComplete="off"
                                                            value={priceListItem?.cartMaximalQuantity ?? ''}
                                                            onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FunctionsIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                        <Alert severity="warning" sx={{ mt: 1 }}>
                            Limity pre minimálne alebo maximálne množstvo v objednávke sú platné iba v rámci objednávkového systému pre zákazníka (eshop a podobne).
                            Limity nie sú uplatnené pri vytváraní dokladu cez administráciu (tu je možné použiť akékoľvek množstvo).
                            Ak niektorý z cenníkov nemá nastavené limity, tak sú automaticky aplikované limity pre základnú cenu (ak existujú).
                        </Alert>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    )
};

export default ProductCreatePrice;
