import React, { useEffect, useState } from 'react';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { ProductStock, Warehouse, StockAvailability } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';

// Komponenty
import { Chip, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import ApartmentIcon from '@mui/icons-material/Apartment';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';
import PlaceIcon from '@mui/icons-material/Place';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateStockProps extends ProductCreateComponentProps {
    warehouses: Warehouse[];
    stockAvailabilities: StockAvailability[];
};

const ProductCreateStock = (props: ProductCreateStockProps) => {

    // Zmeny vo formulári
    const handleChangeStock = (warehouseId: number, stockDivisionId: number, property: string, value: any) => {
        props.setSource(prev => {
            const item: ProductStock = { ...prev?.stocks?.find(item => item.warehouseId === warehouseId), warehouseId: warehouseId, [property]: value };
            const next = { ...prev, stocks: [...prev?.stocks?.filter(item => !(item.warehouseId === warehouseId)) ?? [], item] };
            return next;
        });
    }

    // Skupiny skladov
    const [warehouseGroups, setWarehouseGroups] = useState<string[]>([]);

    // Automaticky získam zoznam skupín
    useEffect(() => setWarehouseGroups(Array.from(new Set(props.warehouses?.map(item => item.warehouseGroupName ?? '') ?? []))), [props.warehouses]);

    // Upozornenie na limity
    const stockLimitWarning =
        ((props.source.stockMinimalQuantity ?? 0) > 0 && (props.source.stockAvailabilityQuantity ?? 0) < (props.source.stockMinimalQuantity ?? 0)) ||
        ((props.source.stockMaximalQuantity ?? 0) > 0 && (props.source.stockAvailabilityQuantity ?? 0) > (props.source.stockMaximalQuantity ?? 0));

    return (
        <>

            <Paper sx={{ p: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '30%' }}>Množstvo</TableCell>
                                    <TableCell sx={{ width: '30%' }}>Dostupnosť</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <strong>Vlastné sklady</strong>
                                        {stockLimitWarning === true && <Chip label="dosiahnutý limit" color="error" size="small" sx={{ ml: 2 }} />}
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled
                                            sx={{ ...(stockLimitWarning === true ? { outline: (theme) => '2px solid ' + theme.palette.error.main, borderRadius: '5px' } : {}) }}
                                            value={props.source.stockAvailabilityQuantity ?? ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end" sx={{ ...(stockLimitWarning === true ? { color: (theme) => theme.palette.error.main } : {}) }}>
                                                        {stockLimitWarning ? <WarningIcon /> : <ApartmentIcon />}
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <Select fullWidth size="small" labelId="product-create-availability" value={props.source.stockAvailabilityId ?? 0} name="stockAvailabilityId" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}>
                                            <MenuItem value={0}>-</MenuItem>
                                            {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                        </Select>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <strong>Externé sklady</strong>
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled
                                            value={props.source.stockExternalQuantity ?? ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EmojiTransportationIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <strong>Rezervované</strong><br />
                                        nevybavené predajné objednávky (rezerované - dodané)
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled
                                            value={props.source.stockReservedQuantity ?? ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <HistoryToggleOffIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <strong>Objednané</strong><br />
                                        nevybavené nákupné objednávky (objednané - dodané)
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled
                                            value={props.source.stockOrderedQuantity ?? ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <HistoryToggleOffIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '30%' }}>Množstvo</TableCell>
                                    <TableCell sx={{ width: '30%' }}>Dostupnosť</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {warehouseGroups?.map((groupName, index) => (
                                    <React.Fragment key={index}>
                                        {props.warehouses?.filter(stock => (stock.warehouseGroupName ?? '') === groupName)?.map((stock, index) => {
                                            var stockItem = props.source.stocks?.find(item => item.warehouseId === stock.id);
                                            return (
                                                <React.Fragment key={stock.id}>
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {groupName.length > 0 ? groupName + ' / ' : ''}
                                                            {stock.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField disabled={(stock.type ?? 0) === 0} fullWidth size="small" name="quantity" type="text" variant="outlined" autoComplete="off"
                                                                value={stockItem?.quantity ?? ''}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), 0, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {stock.type === 2 ? <EmojiTransportationIcon /> : <ApartmentIcon />}
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select fullWidth size="small" name="stockAvailabilityId"
                                                                value={stockItem?.stockAvailabilityId ?? 0}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), 0, e.target.name, e.target.value) }}>
                                                                <MenuItem key={0} value={0}>-</MenuItem>
                                                                {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                                            </Select>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                ))}

                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '30%' }}>Minimálne</TableCell>
                                    <TableCell sx={{ width: '30%' }}>Maximálne</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        Odporúčaný zostatok (limit)
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <TextField fullWidth size="small" name="stockMinimalQuantity" type="text" variant="outlined" autoComplete="off" value={props.source.stockMinimalQuantity ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <WarningAmberIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <TextField fullWidth size="small" name="stockMaximalQuantity" type="text" variant="outlined" autoComplete="off" value={props.source.stockMaximalQuantity ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <WarningAmberIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '60%' }}>Umiestnenie na sklade (pozícia)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {warehouseGroups?.map((groupName, index) => (
                                    <React.Fragment key={index}>
                                        {props.warehouses?.filter(stock => (stock.warehouseGroupName ?? '') === groupName)?.map((stock, index) => {
                                            var stockItem = props.source.stocks?.find(item => item.warehouseId === stock.id);
                                            return (
                                                <React.Fragment key={stock.id}>
                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {groupName.length > 0 ? groupName + ' / ' : ''}
                                                            {stock.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField fullWidth size="small" name="location" type="text" variant="outlined" autoComplete="off"
                                                                value={stockItem?.location ?? ''}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), 0, e.target.name, e.target.value) }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <PlaceIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                ))}

                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>

        </>
    )
};

export default ProductCreateStock;
