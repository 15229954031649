
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../../utility/Number';
import { NullMinDate } from '../../../utility/Date';

// Modely
import { Gift, GiftTranslation, File, Language, Domain, CustomerGroup, Modules } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, Card, CardMedia, CardActions, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';
import CustomFieldValues from '../../settings/custom_field/CustomFieldValues';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Gift => ({
    id: 0,
    actived: true,
    minimalPrice: 0,
    note: '',
    activedDateFrom: null,
    activedDateTo: null,
    fileId: 0,
    domains: [],
    customerGroups: [],
    translations: [],
    other: {
        fileSrcMedium: ''
    }
});

// Vstupné parametre
export interface GiftCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const GiftCreate = (props: GiftCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Gift>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            if (files.length > 0 && files[0].fileIsImage) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'gift/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Gift);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zákazníckych skupín
    const loadCustomerGroups = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customergroup')
            .then(response => {
                setCustomerGroups(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadCustomerGroups(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: GiftTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'gift', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Darček' : 'Nový darček')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <Grid key={language.id} item xs={12}>
                                        <TextField required margin="dense" name='name' label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                            inputRef={input => index === 0 && (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                            value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 70 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12} mt={1}>
                                <Alert severity="warning">
                                    Darček je do objednávky vložený automaticky iba v rámci objednávkového systému pre zákazníka (eshop a podobne).
                                    Darčeky nie sú automaticky vkladané pri vytváraní objednávky cez administráciu (tu je možné vložiť darček ručne).
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('limits')} onChange={() => handleToggleAccordion('limits')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Obmedzenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="gift-create-domain">Domény</InputLabel>
                                                    <Select multiple labelId="gift-create-domain" label="Domény" name="domains" value={source.domains ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'domains' && setFocus(input)}>
                                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="gift-create-customergroups">Zákaznícke skupiny</InputLabel>
                                                    <Select multiple labelId="gift-create-customergroups" label="Zákaznícke skupiny" name="customerGroups" value={source.customerGroups ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'customerGroups' && setFocus(input)}>
                                                        {customerGroups.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <TextField fullWidth margin="dense" name="minimalPrice" type="text" label="Minimálna suma objednávky" variant="outlined" autoComplete="off" value={source.minimalPrice ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'minimalPrice' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Hodnota objednávky musí byť minimálne v nastavenej sume, inak sa darček nezobrazí. Ak nie je nastavená minimálna suma objednávky, tak bude darček vložený ku každej objednávke.">
                                                                    <ShoppingCartIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Aktívny od" value={NullMinDate(source.activedDateFrom)} onChange={(d) => { handleChange('activedDateFrom', d, 'date') }} inputRef={input => { props.autoFocus === 'activedDateFrom' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Aktívny do" value={NullMinDate(source.activedDateTo)} onChange={(d) => { handleChange('activedDateTo', d, 'date') }} inputRef={input => { props.autoFocus === 'activedDateTo' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12} mt={1}>
                                                <Alert severity="info">
                                                    Pri obmedzeniach (napr. domény) platí pravidlo, že ak je nie je označená žiadna možnosť, tak záznam platí pre všetko.
                                                </Alert>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Interná poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                    inputProps={{ maxLength: 1000 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Grid item xs={12} md={4}>
                                    {(source.fileId ?? 0) > 0 &&
                                        <Card sx={{ mt: 1 }}>
                                            <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                            <CardActions>
                                                <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                            </CardActions>
                                        </Card>
                                    }
                                    <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                                </Grid>
                            </Grid>

                            <CustomFieldValues moduleId={Modules.Gift} values={source.customFieldValues} languages={languages} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <FilesDialog {...filesDialog} />
        </>
    )
}

export default GiftCreate;