import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { EscapeUrl } from '../../utility/Text';
import { NullMinDate } from '../../utility/Date';

// Modely
import { WebStructure, File, SelectItem, WebStructureTargetTypes, WebStructureVisibilityTypes, Category } from '../../models/Models';
import FilesDialog, { FilesDialogProps } from '../file/FilesDialog';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, Card, CardMedia, CardActions, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CategorySelect from '../category/CategorySelect';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): WebStructure => ({
    id: 0,
    actived: true,
    activedDateFrom: null,
    activedDateTo: null,
    ordinalNumber: 0, // automaticky (id == 0)
    fileId: 0,
    categoryId: 0,
    name: '',
    url: '',
    target: 0,
    visibility: 0,
    other: {
        fileSrcMedium: ''
    }
});

// Vstupné parametre
export interface WebStructureCreateProps {
    open: boolean;
    id?: number;
    parentId?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    webStructures: WebStructure[]; // Zoznam tlačidiel
    onSave?: () => void;
    onClose: () => void;
}

const WebStructureCreate = (props: WebStructureCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<WebStructure>(EmptySource());
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const [parentsSource, setParentsSource] = useState<SelectItem[]>([]);
    // const [categoriesSourceSelect, setCategoriesSourceSelect] = useState<SelectItem[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Súbory
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            if (files.length > 0 && files[0].fileIsImage) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource({ ...EmptySource(), parentId: props.parentId ?? 0 });
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'webstructure/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as WebStructure);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pregenerovanie zdroja pre strom nadradených tlačidiel po zmene dát
    useEffect(() => {
        // Zoznam položiek
        const items: SelectItem[] = [];

        // Funkcia pre rekurzívne vygenerovanie položiek (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
        const generate = (parentId: number, parentName: string, level?: number) => {
            if ((level ?? 0) > 30) {
                return [];
            }
            props.webStructures.filter(c => c.parentId === parentId && c.id !== props.id)?.forEach(c => {
                const name = parentName + (parentName.length > 0 ? ' / ' : '') + (c?.name ?? '');
                items.push({ id: c?.id ?? 0, name: name });
                generate(c.id ?? 0, name, (level ?? 0) + 1);
            });
        };
        generate(0, '');

        // Uloženie stavu
        setParentsSource(items);
    }, [props.webStructures, props.id]);

    // Funkcia pre načítanie kategórii
    const loadDataCategories = () => {
        axios
            .get(AppConfig.ApiUri + 'category')
            .then(response => {
                setCategories(response.data as Category[]);
            });
    };
    useEffect(() => loadDataCategories(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Pregenerovanie zdroja pre strom  kategórie po zmene dát
    // useEffect(() => {
    //     // Funkcia pre rekurzívne vygenerovanie položiek (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
    //     const itemsSelect: SelectItem[] = [];
    //     const generateSelect = (parentId: number, parentName: string, level?: number) => {
    //         if ((level ?? 0) > 30) {
    //             return [];
    //         }
    //         categories.filter(d => d.parentId === parentId)?.forEach(d => {
    //             const name = parentName + (parentName.length > 0 ? ' / ' : '') + (d.other?.translationName ?? '');
    //             itemsSelect.push({ id: d?.id ?? 0, name: name });
    //             generateSelect(d.id ?? 0, name, (level ?? 0) + 1);
    //         });
    //     };
    //     generateSelect(0, '');
    //     setCategoriesSourceSelect(itemsSelect);
    // }, [categories]);

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'webstructure', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Tlačidlo' : 'Nové tlačidlo')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívne" />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="create-parent">Nadradené tlačidlo</InputLabel>
                                    <Select required labelId="create-parent" label="Nadradené tlačidlo" value={source.parentId ?? 0} name="parentId" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'parentId' && setFocus(input)}>
                                        <MenuItem value={0}>Žiadne</MenuItem>
                                        {parentsSource.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField required margin="dense" name="url" label="URL stránky" fullWidth variant="outlined" autoComplete="off" value={source.url ?? ''} onChange={(e) => { handleChange(e.target.name, EscapeUrl(e.target.value, false)) }}
                                    inputRef={input => props.autoFocus === 'url' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LinkIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => { handleToggleAccordion('settings') }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nastavenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Aktívne od" value={NullMinDate(source.activedDateFrom)} onChange={(d) => { handleChange('activedDateFrom', d, 'date') }} inputRef={input => { props.autoFocus === 'activedDateFrom' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Aktívne do" value={NullMinDate(source.activedDateTo)} onChange={(d) => { handleChange('activedDateTo', d, 'date') }} inputRef={input => { props.autoFocus === 'activedDateTo' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="webstructure-create-target">Zobraziť ako</InputLabel>
                                                    <Select required labelId="webstructure-create-target" label="Zobraziť ako" value={source.target ?? 0} name="target" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'target' && setFocus(input)}>
                                                        {WebStructureTargetTypes.map(type => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="webstructure-create-visibility">Zobraziť komu</InputLabel>
                                                    <Select required labelId="webstructure-create-visibility" label="Zobraziť komu" value={source.visibility ?? 0} name="visibility" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'visibility' && setFocus(input)}>
                                                        {WebStructureVisibilityTypes.map(type => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <CategorySelect label='Zobraziť kategóriu' activeCategoryId={source.categoryId} categories={categories} onSelect={(id: number) => handleChange('categoryId', id)} onCategoriesChanged={loadDataCategories} />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid item xs={12} md={4}>
                                                    {(source.fileId ?? 0) > 0 &&
                                                        <Card sx={{ mt: 1 }}>
                                                            <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                                            <CardActions>
                                                                <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                                            </CardActions>
                                                        </Card>
                                                    }
                                                    <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <FilesDialog {...filesDialog} />
        </>
    )
}

export default WebStructureCreate;