import React, { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { FileDirectory } from '../../models/Models';

// Komponenty
import { Backdrop, Box, Button, CircularProgress, ListItemIcon, Menu, Paper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ButtonList, { Item } from '../../components/ButtonList';
import FileDirectoryCreate, { FileDirectoryCreateProps } from './FileDirectoryCreate';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import MessageBox, { MessageBoxProps } from '../../components/MessageBox';
import Search from '../../components/Search';
import { SearchInText } from '../../utility/Text';

// Ikony
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';

interface FilesDirectoriesProps {
    directories: FileDirectory[];   // Zoznam zložiek
    activeDirectoryId?: number;     // Id aktívnej zložky
    hiddenDirectoryId?: number;     // Skrytá vetva, napr. ak vyberám nadradenú tak nemôžem dovoliť vybrať seba samú, inak hrozí zacyklenie a to, že sa nevykreslí.
    hideTrash?: boolean;            // Skryť vetvu "nezaradené"
    hideSearch?: boolean;           // Skryť vyhľadávanie
    onChanged?: () => void;         // Udalosť po zmene v zozname
    onClick?: (id: number) => void; // Udalosť po kliknutí
}

const FilesDirectories = (props: FilesDirectoriesProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [directoriesSource, setDirectoriesSource] = useState<Item[]>([]);
    const [directoriesSourceFiltered, setDirectoriesSourceFiltered] = useState<Item[]>([]);
    const [fileDirectoryCreate, setFileDirectoryCreate] = useState<FileDirectoryCreateProps>({
        open: false,
        keepMounted: true,
        directories: [],
        onDirectoriesChanged: props.onChanged,
        onSave: props.onChanged,
        onClose: () => setFileDirectoryCreate(prev => ({ ...prev, open: false }))
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({
        open: false, title: '', children: null, onClose: () => {
            setMessageBox(prev => ({
                ...prev, open: false
            }));
        }
    });
    const [searchDirectories, setSearchDirectories] = useState<string>('');

    // Kontextové menu
    const [directoriesMenuItem, setDirectoriesMenuItem] = useState<Item>();
    const [directoriesMenuEl, setDirectoriesMenuEl] = useState<null | HTMLElement>(null);

    // Pregenerovanie zdroja pre zložky po zmene dát
    useEffect(() => {
        // Funkcia pre rekurzívne vygenerovanie položiek pre button list (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
        const generate = (): Item[] | null => {
            const byParent = (id: number, level?: number): Item[] => {
                if ((level ?? 0) > 30) {
                    return [];
                }
                return props.directories.filter(d => d.parentId === id && d.id !== props.hiddenDirectoryId)?.map(d => ({
                    key: d.id ?? 0,
                    title: d.name ?? '',
                    icon: <FolderOpenIcon />,
                    items: byParent(d.id ?? -1, (level ?? 0) + 1),
                    onClickMore: handleDirectoriesMenuOpen
                })) ?? null;
            };
            return byParent(0);
        };
        const items: Item[] = [
            { key: 0, title: 'Všetko', icon: <FolderIcon />, divider: true },
            ...(generate() ?? []),
            //{ key: -1,  title: 'Nepožité',  icon: <SnippetFolderIcon /> },
            ...((props.hideTrash ?? true) === false ? [{ key: -2, title: 'Kôš', icon: <DeleteIcon /> }] : [])
        ];
        setDirectoriesSource(items);
    }, [props.directories, props.hiddenDirectoryId, props.hideTrash]);

    // Funkcia pre otvorenie kontexového menu zložky
    const handleDirectoriesMenuOpen = (e: HTMLElement, item: Item) => {
        setDirectoriesMenuItem(item);
        setDirectoriesMenuEl(e);
    };

    // Funkcia pre otvorenie kontexového menu zložky

    // Pridať upraviť záznam
    const handleCreate = (id: number, parentId?: number) => {
        setFileDirectoryCreate(prev => ({
            ...prev,
            id: id,
            parentId: parentId,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        // Systémový adresár nie je možné zmazať
        var directoryType = props.directories.find(d => d.id === id)?.type ?? 0;
        if (directoryType > 0) {
            setMessageBox(prev => ({
                ...prev,
                open: true,
                title: 'Systémový priečinok',
                children: 'Tento priečinok nie je povolené vymazať.'
            }));
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento priečinok a jeho pod-priečinky? Súbory budú presunuté medzi všetky súbory.', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'filedirectory/' + id)
                    .then(response => {
                        if (response.data === true && props.onChanged !== undefined) {
                            props.onChanged();
                            // Po vymazaní automaticky označím "všetko", mohol som zmazať aktuálny alebo vnorený adresár, aby som obratom nahral súbor, tak by sa nahral do neexistujceho adresára (ideálne by bolo kontrolvať podľa ID, či išlo o označenú vetvu)
                            if (props.onClick !== undefined && (props.activeDirectoryId ?? 0) > 0) {
                                props.onClick(0);
                            }
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };



    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => {
        if (searchDirectories.length === 0) {
            setDirectoriesSourceFiltered(directoriesSource);
            return;
        }

        // Pravidlá:
        // --------------
        // Zobraziť všetky pod vetvy nájdenej kategórie (ak vyhľadám obuv, tak sa mi zobrazia poltopanky, sandale atd)
        // Zobrazím celú nadradenú vetvu nájdenej kategórie (ak vyhľadám obuv, tak sa mi zobrazí nadradené oblečenie)

        // Riešenie
        // --------------
        // V jednom cykle vytvoriť dva zoznamy: 
        // - Zoznam č.1 (nájdené) - z pôvodného zdroju nájsť v všetky kategórie, ktoré obsahujú kľúčové slovo
        // - Zoznam č.2 (nadradené) - nájsť všetkých parentov pre prvý zoznam až po root
        // Rekurzívne skopírovať kategórie, ktoré:
        // - Ak figuruje v prvom zozname, tak automaticky zobraziť celú vetvu
        // - Ak figuruje v druhom zozname, tak ju pridať s tým, že jej podpoložky sa budú tiež prechádzať

        const foundItems: number[] = [-1, 0]; // Položky všetko a nezradené zobrazujem vždy
        const parentsItems: number[] = [];

        // Nájdem všetky položky a ich naradené vetvy
        props.directories.forEach(category => {
            if (SearchInText(searchDirectories, (category?.name ?? '')) === true) {
                var id = category.id ?? 0;
                if (!foundItems.includes(id)) {
                    foundItems.push(id);

                    // Nájdem celú nadradenú vetvu (9999 = ochrana pred zacyklením kvôli chybne nastaveným parentom: A > B > C > A > B > C...)
                    const addParents = (parentId: number) => {
                        if (parentId === 0 || parentsItems.length >= 9999) {
                            return;
                        }
                        var parent = props.directories.find(p => p.id === parentId);
                        if (parent !== undefined && (parent.id ?? 0) > 0 && !parentsItems.includes(parent.id ?? 0)) {
                            parentsItems.push(parent.id ?? 0);
                            addParents(parent.parentId ?? 0);
                        }
                    };
                    addParents(category.parentId ?? 0);
                }
            }
        });

        // Rekurzívne nájde položky
        const findItems = (items?: Item[]): Item[] => {
            return items?.map(item => {

                // Ak položka nie je ani v prvom ani v druhom zozname, tak ju nezobrazujem
                if (!foundItems.includes(item.key as number) && !parentsItems.includes(item.key as number)) {
                    return { key: null, title: '' };
                }

                // Ak je položka v prvom zozname, tak automaticky zobrazujem celú jej vetvu
                if (foundItems.includes(item.key as number)) {
                    return { ...item };
                }

                // Nájdem podpoložky
                return { ...item, items: findItems(item.items) as Item[] }

            })?.filter(item => item.key !== null) ?? [];
        }

        // Použijem odfiltrovaný zoznam
        setDirectoriesSourceFiltered(findItems(directoriesSource));
    }, [directoriesSource, searchDirectories]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <FileDirectoryCreate {...fileDirectoryCreate} directories={props.directories} />

            {/* Kontextové menu položky */}
            <Menu id="menu-directories" anchorEl={directoriesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(directoriesMenuEl)} onClose={() => setDirectoriesMenuEl(null)} >
                <MenuItem dense onClick={() => { handleCreate(0, directoriesMenuItem?.key ?? 0); setDirectoriesMenuEl(null); }}>
                    <ListItemIcon><AddIcon fontSize="small" /></ListItemIcon> Vložiť nový priečinok
                </MenuItem>
                <MenuItem dense onClick={() => { handleCreate(directoriesMenuItem?.key ?? 0, 0); setDirectoriesMenuEl(null); }}>
                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť priečinok
                </MenuItem>
                <MenuItem dense onClick={() => { handleDelete(directoriesMenuItem?.key ?? 0, directoriesMenuItem?.title ?? ''); setDirectoriesMenuEl(null); }}>
                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať priečinok
                </MenuItem>
            </Menu>

            {/* Vyhľadávanie */}
            {(props.hideSearch ?? false) === false && <Box mb={1}><Search text={searchDirectories} onSearch={s => setSearchDirectories(s)} onClear={() => { setSearchDirectories(''); }} autoFocus={false} /></Box>}

            {/* Zoznam priečinkov (harmonika) */}
            <Paper>
                <ButtonList
                    activedKey={props.activeDirectoryId}
                    dense={true}
                    items={directoriesSourceFiltered ?? []}
                    onClick={(e) => {
                        if (props.onClick !== undefined) {
                            props.onClick(e.key as number);
                        }
                    }} />
                <Box padding={1}>
                    <Button fullWidth variant="outlined" color="secondary" onClick={() => handleCreate(0, ((props.activeDirectoryId ?? 0) > 0 ? props.activeDirectoryId : 0))}>Nový priečinok</Button>
                </Box>
            </Paper>

        </React.Fragment>
    )
}

export default FilesDirectories;