import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { EscapeSlug } from '../../utility/Text';
import { NullMinDate } from '../../utility/Date';

// Modely
import { Domain, WebPage, WebPageBlock, WebPageVisibilityTypes } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import WebPageCreateBlocks, { WebPageCreateBlocksProps } from './WebPageCreateBlocks';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): WebPage => ({
    id: 0,
    domainId: 0,
    actived: true,
    activedDateFrom: null,
    activedDateTo: null,
    url: '',
    templateMode: 0,
    visibility: 0,
    password: '',
    seoIndex: true,
    seoHeadline: '',
    seoTitle: '',
    seoDescription: ''
});

// Vstupné parametre
export interface WebPageCreateProps {
    open: boolean;
    id?: number;
    domainId?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const WebPageCreate = (props: WebPageCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<WebPage>(EmptySource());
    const [domains, setDomains] = useState<Domain[]>([]);
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Spoločné nastavenia pre bloky
    const webPageCreateBlocksProps: WebPageCreateBlocksProps = {
        area: 0,
        areaTitle: '',
        source: {},
        setSource: setSource,
        isAccordionExpanded: isAccordionExpanded,
        onToggleAccordion: handleToggleAccordion
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource(prev => ({
                ...EmptySource(),
                domainId: (props.domainId ?? 0) > 0 ? props.domainId : domains?.find(domain => domain.default)?.id ?? 0
            }));
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'webpage/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                        response.data.blocks = [...((response.data.blocks ?? []) as WebPageBlock[]).filter(item => !(item.showOnAllPages ?? false)).map(item => ({ ...item, id: 0 }))];
                    }
                    setSource(response.data as WebPage);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        setSource(prev => {
            if (property === 'seoHeadline') {
                prev['url'] = EscapeSlug(value);
                prev['seoTitle'] = value;
            }
            return ({ ...prev, [property]: value });
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if ((source.domainId ?? 0) === 0) {
            setWarning({ ...EmptyWarning, text: 'Musíte vybrať doménu!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'webpage', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Stránka' : 'Nová stránka')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="webpage-create-domain">Doména *</InputLabel>
                                    <Select required labelId="webpage-create-domain" label="Doména *" name="domainId" value={source.domainId} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'domainId' && setFocus(input)}>
                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField required margin="dense" name="seoHeadline" label="Nadpis" fullWidth variant="outlined" autoComplete="off" value={source.seoHeadline ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'seoHeadline' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField required margin="dense" name="url" label="URL" fullWidth variant="outlined" autoComplete="off" value={source.url ?? ''} onChange={(e) => { handleChange(e.target.name, EscapeSlug(e.target.value, false)) }}
                                    inputRef={input => props.autoFocus === 'url' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LinkIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} my={1}>
                                <Alert severity="info">
                                    Systém nekontroluje jedinečnosť URL, keďže iná stránka s rovnakou URL nemusí byť práve aktívna (sezónna aktivácia, zobraziť prihlásenému / neprihlásenému).
                                </Alert>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 1 }}></Grid>

                            <Grid item xs={12}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => { handleToggleAccordion('settings') }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nastavenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Aktívne od" value={NullMinDate(source.activedDateFrom)} onChange={(d) => { handleChange('activedDateFrom', d, 'date') }} inputRef={input => { props.autoFocus === 'activedDateFrom' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Aktívne do" value={NullMinDate(source.activedDateTo)} onChange={(d) => { handleChange('activedDateTo', d, 'date') }} inputRef={input => { props.autoFocus === 'activedDateTo' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="webpage-create-visibility">Zobraziť komu</InputLabel>
                                                    <Select required labelId="webpage-create-visibility" label="Zobraziť komu" value={source.visibility ?? 0} name="visibility" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'visibility' && setFocus(input)}>
                                                        {WebPageVisibilityTypes.map(type => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="webpage-create-template-mode">Režim zobrazenia (šablóna)</InputLabel>
                                                    <Select required labelId="webpage-create-template-mode" label="Režim zobrazenia (šablóna)" value={source.templateMode ?? 0} name="templateMode" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'templateMode' && setFocus(input)}>
                                                        <MenuItem value={0}>-</MenuItem>
                                                        <MenuItem value={1}>Režim č.1</MenuItem>
                                                        <MenuItem value={2}>Režim č.2</MenuItem>
                                                        <MenuItem value={3}>Režim č.3</MenuItem>
                                                        <MenuItem value={4}>Režim č.4</MenuItem>
                                                        <MenuItem value={5}>Režim č.5</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {(source.visibility ?? 0) === 3 && (
                                                <Grid item xs={12} md={6}>
                                                    <TextField margin="dense" name="password" label="Vstup s heslom" fullWidth variant="outlined" autoComplete="off" value={source.password ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'password' && setFocus(input)}
                                                        inputProps={{ maxLength: 50 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EditIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>
                                            )}

                                            {(source.templateMode ?? 0) > 0 && (
                                                <Grid item xs={12}>
                                                    <Alert severity="info" sx={{ my: 1 }}>
                                                        Šablóna môže podporovať rôzne režimy zobrazenia (napr. skrytý bočný panel, iná farebná schéma a podobne). Význam je definovaný priamo šablónou danej domény, preto rovnaký režim sa môže rôzne zobrazovať naprieč doménami.
                                                    </Alert>
                                                </Grid>
                                            )}

                                            <Grid item xs={12} mt={3}>
                                                <Typography textAlign="center" variant="h6" gutterBottom>SEO</Typography>
                                            </Grid>

                                            <Grid item xs={12} mb={1}>
                                                <FormControlLabel control={<Switch checked={source.seoIndex} name="seoIndex" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Povoliť indexovanie" />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField margin="dense" name="seoTitle" label="Titulok" fullWidth variant="outlined" autoComplete="off" value={source.seoTitle ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'seoTitle' && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField margin="dense" name="seoDescription" label="Krátky popis" fullWidth variant="outlined" autoComplete="off" value={source.seoDescription ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'seoDescription' && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={3} mb={2}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Hlavná oblasť</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={1} areaTitle="Obsah" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={2} areaTitle="Hlavička" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={3} areaTitle="Ľavý panel" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={4} areaTitle="Pravý panel" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={5} areaTitle="Pätička" />
                            </Grid>

                            <Grid item xs={12} mt={3} mb={2}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Doplnková oblasť</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={6} areaTitle="Oblasť č. 1" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={7} areaTitle="Oblasť č. 2" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={8} areaTitle="Oblasť č. 3" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={9} areaTitle="Oblasť č. 4" />
                                <WebPageCreateBlocks {...webPageCreateBlocksProps} source={source} area={10} areaTitle="Oblasť č. 5" />
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default WebPageCreate;