import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Article, File, SelectItem } from '../../../models/Models';

// Komponenty
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControlLabel, Switch, Typography, Accordion, AccordionDetails, AccordionSummary, Card, CardActions, CardMedia, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TinyMCEDialog, { TinyMCEDialogProps } from '../../tinymce/TinyMCEDialog';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';
import Iframe from '../../../components/Iframe';
import ContentCategories from '../content_category/ContentCategories';
import { TinyMCEStyleClient } from '../../tinymce/TinyMCEStyleClient';

// Ikony
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NullMinDate } from '../../../utility/Date';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Article => ({
    id: 0,
    date: new Date(),
    actived: true,
    contentCategoryId: 0,
    fileId: 0,
    name: '',
    content: '',
    note: '',
    other: {
        fileSrcMedium: ''
    }
});

// Predpis pre argumenty TinyMCE
interface TinyMCEArgument {
    property: string;
}

// Vstupné parametre
export interface ArticleCreateProps {
    open: boolean;
    id?: number;
    contentCategoryId?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    categories: SelectItem[];
    onCategoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSave?: () => void;
    onClose: () => void;
}

const ArticleCreate = (props: ArticleCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Article>(EmptySource());
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const [tinyMCEDialog, setTinyMCEDialog] = useState<TinyMCEDialogProps>({
        open: false,
        content: '',
        contentStyle: 'body { font-family: Arial, sans-serif; }', // CSS štýl, ktorý sa pridáva do vygenerovaného HTML ako predvolený štýl
        onSave: (content: string, argument: TinyMCEArgument) => {
            handleChange(argument.property, content);
            handleTinymceClose();
        },
        onClose: () => handleTinymceClose()
    });
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            if (files.length > 0 && files[0].fileIsImage) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie HTML editoru
    const handleTinymceOpen = (argument: TinyMCEArgument, title: string, content: string) => {
        setTinyMCEDialog(prev => ({ ...prev, open: true, title: title, argument: argument, content: content, /*contentTagsIds: []*/ }));
    };
    const handleTinymceClose = () => {
        setTinyMCEDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(() => {
            var empty = EmptySource();
            empty.contentCategoryId = props.contentCategoryId ?? empty.contentCategoryId;
            return empty;
        });
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'article/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Article);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'article', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <TinyMCEDialog {...tinyMCEDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="lg" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Článok' : 'Nový článok')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Dátum" value={NullMinDate(source.date)} onChange={(d) => { handleChange('date', d, 'date') }} inputRef={input => { props.autoFocus === 'date' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <ContentCategories
                                    categories={props.categories}
                                    selectedId={source.contentCategoryId ?? 0}
                                    onSelect={(id) => setSource(prev => ({ ...prev, contentCategoryId: id }))}
                                    onChanged={props.onCategoriesChanged}
                                />
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => { handleToggleAccordion('settings') }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nastavenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={3} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid item xs={12} md={4}>
                                                    {(source.fileId ?? 0) > 0 &&
                                                        <Card sx={{ mt: 1 }}>
                                                            <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                                            <CardActions>
                                                                <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                                            </CardActions>
                                                        </Card>
                                                    }
                                                    <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={2} sx={{ height: '350px' }}>
                                <Iframe title={'Obsah článku'} content={(source.content ?? '') + '<style>' + (tinyMCEDialog.contentStyle ?? '') + TinyMCEStyleClient + '</style>'} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => { handleTinymceOpen({ property: 'content' }, 'Obsah článku', source.content ?? ''); }}>Upraviť</Button>
                            </Grid>
                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <FilesDialog {...filesDialog} />
        </>
    )
}

export default ArticleCreate;