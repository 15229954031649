
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../../utility/Number';
import { UniqueCodesGenerate } from '../../../utility/UniqueCodes';
import { NullMinDate } from '../../../utility/Date';

// Modely
import { Voucher, VoucherCode, Domain, CustomerGroup, ProductLite, Category, VoucherProduct } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControlLabel, Switch, FormControl, InputLabel, MenuItem, Select, IconButton, Table, TableBody, TableCell, TableRow, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ProductsDialog, { ProductsDialogProps } from '../../product/ProductsDialog';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import VoucherCreateCodes from './VoucherCreateCodes';
import Categories from '../../category/Categories';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import PercentIcon from '@mui/icons-material/Percent';
import EuroIcon from '@mui/icons-material/Euro';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Voucher => ({
    id: 0,
    actived: true,
    name: '',
    discount: 0,
    type: 0,
    disposable: true,
    validDateFrom: null,
    validDateTo: null,
    minimalPrice: 0,
    note: '',
    domains: [],
    customerGroups: [],
    categories: [],
    products: [],
    productsBlacklist: [],
    codes: []
});

// Vstupné parametre
export interface VoucherCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const VoucherCreate = (props: VoucherCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Voucher>(EmptySource());
    const [domains, setDomains] = useState<Domain[]>([]);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Generovanie kódov
    const maxCodeCount = 1000;
    const [newCodeCount, setNewCodeCount] = useState<number | undefined>(1);

    // Produkty
    const [productsDialog, setProductsDialog] = useState<ProductsDialogProps>({
        open: false,
        keepMounted: false,
        onSelect: (products: ProductLite[], whitelist: any) => {
            if (products.length > 0) {
                if (whitelist === true) {
                    setSource(prev => {
                        const changed: VoucherProduct[] = [...prev.products ?? []];
                        products.forEach(product => {
                            if (changed.find(item => item.productId === product.id) === undefined) {
                                changed.push({ productId: product.id, productCode: product.code, productName: product.name });
                            }
                        });
                        return ({ ...prev, products: changed })
                    });
                } else {
                    setSource(prev => {
                        const changed: VoucherProduct[] = [...prev.productsBlacklist ?? []];
                        products.forEach(product => {
                            if (changed.find(item => item.productId === product.id) === undefined) {
                                changed.push({ productId: product.id, productCode: product.code, productName: product.name });
                            }
                        });
                        return ({ ...prev, productsBlacklist: changed })
                    });
                }
            }
            handleProductsClose();
        },
        onClose: () => handleProductsClose()
    });

    // Zobrazenie produktov
    const handleProductsOpen = (whitelist: any) => {
        setProductsDialog(prev => ({ ...prev, argument: whitelist, open: true }));
    };
    const handleProductsClose = () => {
        setProductsDialog(prev => ({ ...prev, open: false }));
    };

    // Kategórie
    const [categories, setCategories] = useState<Category[]>([]);

    // Funkcia pre načítanie kategórii
    const loadDataCategories = () => {
        axios
            .get(AppConfig.ApiUri + 'category')
            .then(response => {
                setCategories(response.data as Category[]);
            });
    };
    useEffect(() => loadDataCategories(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded(['codes']);
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource(EmptySource());
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'voucher/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                        // Vynulujem aj kódy, nie len id, keďže sú tam ďalšie informácie ako počet objednávok a podobne
                        var codesCount = (response.data.codes.length ?? 0);
                        if (codesCount > 0) {
                            const codes = response.data.codes as VoucherCode[];
                            response.data.codes = [...codes.map(code => { return { code: code.code, other: { orders: 0 } } })];
                        }
                        // Ponúknem možnosť pregenerovať kódy
                        if (codesCount > 0) {
                            setConfirm(prev => ({
                                ...prev, open: true, title: 'Upozornenie', children: 'Kópia obsahuje rovnaké kódy ako pôvodný záznam. Kódy musia byť v období platnosti unikátne, chcete preto vygenerovať nové kódy (celkom: ' + codesCount + ')?', onConfirm: () => {
                                    setConfirm(prev => ({ ...prev, open: false }));
                                    var codes = UniqueCodesGenerate(codesCount);
                                    if (codes.length > 0) {
                                        setSource(prev => ({ ...prev, codes: [...codes.map(code => { return { code: code, other: { orders: 0 } } })] }));
                                    }
                                }
                            }));
                        }
                    }
                    setSource(response.data as Voucher);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zákazníckych skupín
    const loadCustomerGroups = () => {
        axios
            .get(AppConfig.ApiUri + 'customergroup')
            .then(response => {
                setCustomerGroups(response.data);
            });
    };
    useEffect(() => loadCustomerGroups(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Automatická úprava formulára pri otvorení
    useEffect(() => {
        if (props.open) {
            if (warning.text.length > 0) {
                setWarning(EmptyWarning);
            }
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        if (source.type === 2) {
            source.discount = 0;
        }

        axios
            .post(AppConfig.ApiUri + 'voucher', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Vygenerovanie nových kódov
    const handleGenerateCodes = () => {
        var count = (newCodeCount ?? 0);
        if (count > (maxCodeCount - (source.codes?.length ?? 0))) {
            count = (maxCodeCount - (source.codes?.length ?? 0));
        }
        if (count > 0) {
            var codes = UniqueCodesGenerate(count);
            if (codes.length > 0) {
                setSource(prev => ({ ...prev, codes: [...prev.codes ?? [], ...codes.map(code => { return { voucherId: 0, code: code, other: { orders: 0 } } })] }));
            }
        }
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Zľavový kupón' : 'Nový zľavový kupón')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={4} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12} sm={8} mb={1}>
                                <FormControlLabel control={<Switch checked={source.disposable} name="disposable" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Jednorázové kódy' />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth margin="dense" name="minimalPrice" type="text" label="Minimálna suma objednávky" variant="outlined" autoComplete="off" value={source.minimalPrice ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'minimalPrice' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required fullWidth disabled={source.type === 2} margin="dense" name="discount" type="text" label="Výška zľavy" variant="outlined" autoComplete="off" value={source.discount ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'discount' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {source.type === 0 ? <EuroIcon /> : <PercentIcon />}
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="voucher-create-type">Typ zľavy</InputLabel>
                                    <Select required labelId="voucher-create-type" label="Typ zľavy" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        <MenuItem value={0}>Fixná suma</MenuItem>
                                        <MenuItem value={1}>Percentá</MenuItem>
                                        <MenuItem value={2}>Doprava a platba zdarma</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Alert severity="warning">
                                    Zľavové kupóny je možné použiť iba v rámci objednávkového systému pre zákazníka (eshop a podobne).
                                    Zľavové kupóny nie je možné použiť pri vytváraní objednávky cez administráciu (tu je možné vložiť zľavu ručne).
                                </Alert>
                            </Grid>


                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('codes')} onChange={() => handleToggleAccordion('codes')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Zľavové kódy ({source.codes?.length ?? 0})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {(source.codes?.length ?? 0) > 0 &&
                                            <VoucherCreateCodes rows={[...source.codes ?? []]} onChange={(codes: VoucherCode[]) => handleChange('codes', codes)} />
                                        }
                                        <TextField sx={{ mt: 3 }} fullWidth margin="dense" size="small" name="maxCodeCount" type="number" label={'Počet kódov (max: ' + (maxCodeCount - (source.codes?.length ?? 0)) + ')'} variant="outlined" value={newCodeCount ?? ''} onChange={(e) => { setNewCodeCount(ConvertToInt(e.target.value)) }}
                                            inputProps={{ min: 0, max: 99999999, step: 1 }}
                                            onKeyDown={(e) => {
                                                if (e.code === 'Enter') {
                                                    e.preventDefault();
                                                    handleGenerateCodes();
                                                    return;
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                        <Button fullWidth disabled={(source.codes?.length ?? 0) === maxCodeCount} variant="contained" color="secondary" sx={{ mt: 1 }} onClick={handleGenerateCodes}>Generovať kódy</Button>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('limits')} onChange={() => handleToggleAccordion('limits')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Obmedzenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={7}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="voucher-create-domain">Domény</InputLabel>
                                                    <Select multiple labelId="voucher-create-domain" label="Domény" name="domains" value={source.domains ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'domains' && setFocus(input)}>
                                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={5}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Platnosť od" value={NullMinDate(source.validDateFrom)} onChange={(d) => { handleChange('validDateFrom', d, 'date') }} inputRef={input => { props.autoFocus === 'validDateFrom' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12} sm={7}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="voucher-create-customergroups">Povolené zákaznícke skupiny</InputLabel>
                                                    <Select multiple labelId="voucher-create-customergroups" label="Povolené zákaznícke skupiny" name="customerGroups" value={source.customerGroups ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'customerGroups' && setFocus(input)}>
                                                        {customerGroups.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={5}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                                    <FormControl margin="dense" fullWidth>
                                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Platnosť do" value={NullMinDate(source.validDateTo)} onChange={(d) => { handleChange('validDateTo', d, 'date') }} inputRef={input => { props.autoFocus === 'validDateTo' && setFocus(input) }} />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={12} mt={1}>
                                                <Alert severity="info">
                                                    Pri obmedzeniach (napr. domény) platí pravidlo, že ak je nie je označená žiadna možnosť, tak záznam platí pre všetko.
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('products')} onChange={() => handleToggleAccordion('products')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Produkty ({(source.products?.length ?? 0) + (source.productsBlacklist?.length ?? 0)})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography textAlign="center" variant="h6" gutterBottom>Povolené produkty</Typography>
                                        <Alert severity="info" sx={{ mb: 1 }}>
                                            Vybraním povolených produktov je možné určiť konkrétne produkty, pre ktoré je kupón platný. Ak nie sú vybrané žiadne produkty, tak je kupón platný pre všetky s výnimkou zakázaných produktov.
                                        </Alert>
                                        {(source.products?.length ?? 0) > 0 &&
                                            <Table size="small">
                                                <TableBody>
                                                    {source.products?.map((product, index) => (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell>
                                                                <strong>{product.productCode}</strong> - {product.productName}
                                                            </TableCell>
                                                            <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                <IconButton aria-label="Vymazať" size="small" onClick={() => setSource((p) => ({ ...p, products: p.products?.filter(item => item !== product) ?? [] }))}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        }
                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleProductsOpen(true)}>Pridať produkt</Button>

                                        <Typography mt={3} textAlign="center" variant="h6" gutterBottom>Zakázané produkty</Typography>
                                        <Alert severity="info" sx={{ mb: 1 }}>
                                            Vybraním zakázaných produktov je možné určiť konkrétne produkty, pre ktoré kupón nie je platný a to ani v prípade, že sa nachádzajú v zozname povolených produktov alebo spadajú pod povolené kategórie.
                                        </Alert>

                                        {(source.productsBlacklist?.length ?? 0) > 0 &&
                                            <Table size="small">
                                                <TableBody>
                                                    {source.productsBlacklist?.map((product, index) => (
                                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell>
                                                                <strong>{product.productCode}</strong> - {product.productName}
                                                            </TableCell>
                                                            <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                <IconButton aria-label="Vymazať" size="small" onClick={() => setSource((p) => ({ ...p, productsBlacklist: p.productsBlacklist?.filter(item => item !== product) ?? [] }))}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        }
                                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleProductsOpen(false)}>Pridať produkt</Button>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('categories')} onChange={() => handleToggleAccordion('categories')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Kategórie ({source.categories?.length ?? 0})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography textAlign="center" variant="h6" gutterBottom>Povolené kategórie</Typography>
                                        <Alert severity="info" sx={{ mb: 1 }}>
                                            Vybraním povolených kategórii je možné určiť produkty, pre ktoré je kupón platný.
                                            V zozname je potrebné označiť tie vetvy, pod ktoré spadajú produkty so svojou hlavnou kategóriou (alternatívne kategórie sú ignorované).
                                            Pokiaľ nie je označená žiadna kategória, tak je kupón platný pre produkty zo všetkých kategórii.
                                        </Alert>
                                        <Categories checklist={true}
                                            categories={categories}
                                            activeCategoryId={0}
                                            selectedCategories={source.categories}
                                            onCategoriesChanged={loadDataCategories}
                                            onSelectionChanged={(c) => setSource((p) => ({ ...p, categories: c }))} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Interná poznámka" variant="outlined" value={source.note} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <ProductsDialog {...productsDialog} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />
        </>
    )
}

export default VoucherCreate;