import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { File, FileDirectory, Modules } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControlLabel, Switch, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CustomFieldValues from '../settings/custom_field/CustomFieldValues';
import FileDirectorySelect from './FileDirectorySelect';

// Ikony
import EditIcon from '@mui/icons-material/Edit';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): File => ({
    id: 0,
    fileDirectoryId: 0,
    actived: true,
    name: '',
    watermark: false
});

// Vstupné parametre
export interface FileCreateProps {
    open: boolean;
    id?: number;
    autoFocus?: string;
    keepMounted?: boolean;
    directories: FileDirectory[];   // Zoznam zložiek
    onDirectoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSave?: () => void;
    onClose: () => void;
}

const FileCreate = (props: FileCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<File>({ ...EmptySource() });

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource({ ...EmptySource() });
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'file/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    setSource(response.data as File);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'file', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Súbor' : 'Nový súbor')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={6}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            {(source.fileIsImage ?? false) && (
                                <Grid item xs={6}>
                                    <FormControlLabel control={<Switch checked={source.watermark} name="watermark" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Vodoznak" />
                                </Grid>
                            )}

                            {(source.actived ?? false) === false && (
                                <Grid item xs={12}>
                                    <Alert severity="info" sx={{ my: 1 }}>
                                        Neaktívny súbor sa na niektorých miestach nezobrazuje, napríklad v objednávkovom systéme pre zákazníka (eshop a podobne).
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <FileDirectorySelect label='Priečinok' activeDirectoryId={source.fileDirectoryId} directories={props.directories} onSelect={(id: number) => handleChange('fileDirectoryId', id)} onDirectoriesChanged={props.onDirectoriesChanged} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            
                            <CustomFieldValues moduleId={Modules.File} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...prev, name: '', fileDirectoryId: 0, actived: true }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default FileCreate;