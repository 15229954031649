import { useState } from 'react';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { ProductSupplier, Unit } from '../../models/Models';

// Utility

// Komponenty
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ProductCreateSupplierEdit, { ProductCreateSupplierEditProps } from './ProductCreateSupplierEdit';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FormatDecimal } from '../../utility/Format';
import { styled } from '@mui/styles';


// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateSupplierProps extends ProductCreateComponentProps {
    units: Unit[];
};

const ProductCreateSupplier = (props: ProductCreateSupplierProps) => {

    // Editácia záznamu
    const [productCreateSupplierEdit, setProductCreateSupplierEdit] = useState<ProductCreateSupplierEditProps>({
        open: false,
        keepMounted: true,
        units: [],
        supplier: {},
        onSave: (supplier: ProductSupplier) => {
            props.setSource(prev => {
                const changed = [...prev?.suppliers ?? []];
                // Jeden partner môže figurovať v zozname iba raz
                if (changed.find(item => item.customerId === supplier.customerId) !== undefined) {
                    changed.every((item, itemIndex) => {
                        if (item.customerId === supplier.customerId) {
                            changed[itemIndex] = supplier;
                            return false;
                        }
                        return true;
                    });
                }
                else {
                    changed.push(supplier);
                }
                return { ...prev, suppliers: changed };
            });
        },
        onClose: () => handleSupplierClose()
    });
    const handleSupplierEdit = (supplier: ProductSupplier) => {
        setProductCreateSupplierEdit((prev: ProductCreateSupplierEditProps) => ({ ...prev, open: true, supplier: supplier }));
    };
    const handleSupplierClose = () => {
        setProductCreateSupplierEdit((prev: ProductCreateSupplierEditProps) => ({ ...prev, open: false }));
    };
    const handleSupplierAdd = () => {
        handleSupplierEdit({});
    }
    const handleDeleteSupplier = (index: number) => {
        props.setSource(prev => {
            let changed = prev.suppliers ?? [];
            return { ...prev, suppliers: changed.filter(deleted => deleted !== changed[index]) ?? [] };
        });
    }

    // Definovanie zdieľaného štýlu pre všetky bunky
    const TableCellNowrap = styled(TableCell)({
        paddingRight: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 200
    });

    return (
        <>
            {(props.source.suppliers?.length ?? 0) > 0 && (
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCellNowrap>Partner</TableCellNowrap>
                                    <TableCellNowrap>Typ</TableCellNowrap>
                                    <TableCellNowrap align='right'>Cena (bez DPH)</TableCellNowrap>
                                    <TableCellNowrap align='center'>Min / Max</TableCellNowrap>
                                    <TableCellNowrap>Kód</TableCellNowrap>
                                    <TableCellNowrap>Názov</TableCellNowrap>
                                    <TableCellNowrap align='center'>Dodanie (dni)</TableCellNowrap>
                                    <TableCellNowrap>Poznámka</TableCellNowrap>
                                    <TableCell sx={{ minWidth: '100px' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.source.suppliers?.map((supplier, index) => {
                                    return (
                                        <TableRow
                                            key={supplier.customerId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCellNowrap>{supplier.customerName}</TableCellNowrap>
                                            <TableCellNowrap>
                                                {(supplier.customerType ?? 0) === 0 && 'Dodávateľ'}
                                                {(supplier.customerType ?? 0) === 1 && 'Výrobca'}
                                                {(supplier.customerType ?? 0) === 2 && 'Iné'}
                                            </TableCellNowrap>
                                            <TableCellNowrap align='right'>{FormatDecimal(supplier.price ?? 0, 2)}</TableCellNowrap>
                                            <TableCellNowrap align='center'>{(supplier.minimalQuantity ?? 0) > 0 ? supplier.minimalQuantity : '-'} / {(supplier.maximalQuantity ?? 0) > 0 ? supplier.maximalQuantity : '-'} {supplier.unitName}</TableCellNowrap>
                                            <TableCellNowrap>{supplier.code}</TableCellNowrap>
                                            <TableCellNowrap>{supplier.name}</TableCellNowrap>
                                            <TableCellNowrap align='center'>{supplier.deliveryTime ?? '0'}</TableCellNowrap>
                                            <TableCellNowrap>{supplier.note}</TableCellNowrap>
                                            <TableCellNowrap align="right">
                                                <IconButton aria-label="Upraviť" title="Upraviť" size="small" onClick={() => handleSupplierEdit(supplier)}>
                                                    <EditIcon color="primary" fontSize="small" />
                                                </IconButton>
                                                <IconButton aria-label="Vymazať" title="Vymazať" size="small" onClick={() => handleDeleteSupplier(index)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </TableCellNowrap>
                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            )}

            <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleSupplierAdd()}>Pridať dodávateľa</Button>

            <ProductCreateSupplierEdit {...productCreateSupplierEdit} units={props.units} />
        </>
    )
}

export default ProductCreateSupplier;